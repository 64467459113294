import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { Metric, Context } from "@amzn/katal-metrics";
import { Button, Dropdown } from "@amzn/alchemy-components-react";
import {PageType, TenantID, WorkflowState, WorkflowStatus, WorkflowType} from "src/common/enums";
import { NETWORK_ONLY } from "src/common/constants";
import { convertDateTimeToLocaleFormat, convertDateToLocaleFormat } from "src/common/util";
import { LIST_WORKFLOWS_QUERY } from "src/common/gql-operations";
import { PageProps } from "src/pages/page-interface";
import { RenderWorkflowCards } from "src/pages/workflows/render-workflow-cards";
import { BreadCrumbs } from "src/components/breadcrumb";
import { CardItem } from "src/components/cards/card-item";
import { WorkflowDetails } from "src/models/workflow-details";
import { logger } from "src/logger";
import initialMetricsPublisher from 'src/metrics';
import { WorkflowCard } from "src/models/workflow-card";

const cloudWatchDimensions = [
    new Metric.String('page', 'workflow-list'),
]

// @ts-ignore
const additionalMetricsContext = new Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

export const WorkflowList = (props: PageProps) => {

    const {t} = useTranslation();
    const locale = document.documentElement.lang;   // e.g. en-US

    // This list only includes workflows that are implemented and relevant to users
    // - ALL isn't an option since pagination is not implemented, and we want to try to reduce the number of workflows
    //   returned for a single request as much as possible
    // - Parent workflows such as (AFTX_3PL_BULK_USER_ONBOARDING, BASELINING_ALL, etc.) are hidden since all they do is
    //   trigger child workflows which are what a user actually cares about
    // - The MFA_REGISTRATION workflow is hidden since the status and all actions (such as generate a new OTP) can be
    //   accomplished from the MFA Registration modal itself
    const workflowTypes = [
        {label: WorkflowType.AFTX_3PL_ASSOCIATE_ONBOARDING.toUpperCase(), value: WorkflowType.AFTX_3PL_ASSOCIATE_ONBOARDING},
        {label: WorkflowType.AFTX_3PL_USER_ADMIN_ONBOARDING.toUpperCase(), value: WorkflowType.AFTX_3PL_USER_ADMIN_ONBOARDING},
        {label: WorkflowType.USER_TERMINATION.toUpperCase(), value: WorkflowType.USER_TERMINATION},
        {label: WorkflowType.BASELINING_ASSOCIATE.toUpperCase(), value: WorkflowType.BASELINING_ASSOCIATE},
        {label: WorkflowType.BASELINING_USER_ADMIN_PRIMARY.toUpperCase(), value: WorkflowType.BASELINING_USER_ADMIN_PRIMARY},
        {label: WorkflowType.BASELINING_USER_ADMIN_SECONDARY.toUpperCase(), value: WorkflowType.BASELINING_USER_ADMIN_SECONDARY},
    ];

    // The list doesn't include WorkflowStatus.COMPLETED since that would probably cause confusion if displayed
    const workflowStatuses = [
        {label: t('all'), value: WorkflowStatus.ALL},
        {label: t('in-progress'), value: WorkflowStatus.ACTIVE},
        {label: t('succeeded'), value: WorkflowStatus.SUCCEEDED},
        {label: t('failed'), value: WorkflowStatus.FAILED}
    ];

    const [workflowCards, setWorkflowCards] = useState<Map<string, WorkflowCard>>(new Map());
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [workflowStatus, setWorkflowStatus] = useState<string>(workflowStatuses[0].value);
    const [workflowType, setWorkflowType] = useState<string>(workflowTypes[0].value);

    const [listWorkflows, {loading}] = useLazyQuery(LIST_WORKFLOWS_QUERY, {
        fetchPolicy: NETWORK_ONLY, // don't cache
        onCompleted: data => {
            logger.info(`Retrieved ${data.listWorkflows.workflowDetails.length} records from listWorkflows.`);
            graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.SUCCESS', 1);

            const workflowCards = new Map<string, WorkflowCard>();
            const workflows: WorkflowDetails[] = data.listWorkflows.workflowDetails;

            for (const workflow of workflows) {
                workflowCards.set(workflow.workflowId, {
                    items: getCardItems(workflow),
                    expanded: false,
                    header: getHeader(workflow),
                    state: workflow.workflowState as WorkflowState
                });
            }

            setWorkflowCards(workflowCards);
            setErrorMessage(undefined);
        },
        onError: error => {
            logger.error("Call to listWorkflows failed!", error);
            graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.ERROR', 1);
            setWorkflowCards(new Map());
            setErrorMessage(error.message);
        }
    });

    // Executes one time when page first loads
    useEffect(() => {
        props.setActivePage(PageType.WORKFLOW_LIST);
    }, []);

    // Executes every time one of the filters changes
    useEffect(() => {
        callListWorkflows();
    }, [workflowType, workflowStatus]);

    /**
     * Calls to get the list of workflows.
     */
    const callListWorkflows = () => {
        const variables: any = {
            listWorkflowsInput: {
                tenantId: TenantID.AFTX,
                includeChildWorkflows: true,
                workflowType: workflowType
            }
        };

        if (workflowStatus !== 'ALL') {
            variables.listWorkflowsInput.workflowStatus = workflowStatus;
        }

        logger.info("Calling listWorkflows.", variables.listWorkflowsInput);
        graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.INVOCATION', 1);
        listWorkflows({
            variables: variables,
        });
    }

    /**
     * Gets the header for the workflow card.
     *
     * @param workflow The workflow details from which to get the data for the header.
     */
    const getHeader = (workflow: WorkflowDetails) => {
        const workflowStateData = JSON.parse(workflow.workflowStateData!);
        const scope = workflowStateData["scope"];

        let header = `${workflow.workflowType.toLocaleUpperCase(locale)}`;

        if (
            (WorkflowType.AFTX_3PL_ASSOCIATE_ONBOARDING === workflow.workflowType) ||
            (WorkflowType.AFTX_3PL_USER_ADMIN_ONBOARDING === workflow.workflowType)
        ) {
            const firstName = workflowStateData["legalName.firstName"];
            const lastName = workflowStateData["legalName.lastName"];
            header += `: ${scope} - ${firstName} ${lastName}`;
        } else if (WorkflowType.USER_TERMINATION === workflow.workflowType) {
            const firstName = workflowStateData["firstName"];
            const lastName = workflowStateData["lastName"];
            header += `: ${scope} - ${firstName} ${lastName}`;
        } else if (
            (WorkflowType.BASELINING_ASSOCIATE === workflow.workflowType) ||
            (WorkflowType.BASELINING_USER_ADMIN_PRIMARY === workflow.workflowType) ||
            (WorkflowType.BASELINING_USER_ADMIN_SECONDARY === workflow.workflowType)
        ) {
            header += `: ${scope} - ${convertDateToLocaleFormat(locale, new Date(workflow.creationDate))}`;
        }

        return header;
    }

    /**
     * Gets the list of {@link CardItem} from the {@link WorkflowDetails} object.
     *
     * @param workflow The workflow from which to get the data for the card items.
     */
    const getCardItems = (workflow: WorkflowDetails): CardItem[] => {
        return [
            {
                attributeName: t('current-step'),
                attributeValue: workflow.workflowCurrentStep.toLocaleUpperCase(locale),
            },
            {
                attributeName: t('creation-date'),
                attributeValue: workflow.creationDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(workflow.creationDate)) : '',
            },
            {
                attributeName: t('completion-date'),
                attributeValue: workflow.completionDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(workflow.completionDate)) : '',
            },
            {
                attributeName: t('workflow-id'),
                attributeValue: workflow.workflowId
            }
        ];
    }

    /**
     * Expands all workflow cards.
     *
     * @param workflowCards The workflow cards to expand.
     */
    const expandAllCards = (workflowCards: Map<string, WorkflowCard>) => {
        for (const workflowId of workflowCards.keys()) {
            const workflowCard = workflowCards.get(workflowId)!;
            workflowCards.set(workflowId, {
                items: workflowCard.items,
                expanded: true,
                header: workflowCard.header,
                state: workflowCard.state
            });
        }

        setWorkflowCards(new Map(workflowCards));
    }

    /**
     * Collapses all workflow cards.
     *
     * @param workflowCards The workflow cards to collapse.
     */
    const collapseAllCards = (workflowCards: Map<string, WorkflowCard>) => {
        for (const workflowId of workflowCards.keys()) {
            const workflowCard = workflowCards.get(workflowId)!;
            workflowCards.set(workflowId, {
                items: workflowCard.items,
                expanded: false,
                header: workflowCard.header,
                state: workflowCard.state
            });
        }

        setWorkflowCards(new Map(workflowCards));
    }

    /**
     * Expands the workflow card if it's collapsed or collapses the workflow card if it's expanded.
     *
     * @param workflowId The workflow id of the workflow card to expand or collapse.
     */
    const toggleExpand = (workflowId: string) => {
        const workflowCard = workflowCards.get(workflowId)!;
        workflowCards.set(workflowId, {
            items: workflowCard.items,
            expanded: !workflowCard.expanded,
            header: workflowCard.header,
            state: workflowCard.state
        });

        setWorkflowCards(new Map(workflowCards));
    }

    /**
     * Refreshes the list of workflows.
     */
    const refresh = () => {
        logger.info("Refreshing list of workflows.");
        callListWorkflows();
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{tag: t('workflows'), path: '/workflow'}]}/>
            </div>
            <div className="row b-background mx-0">
                <div className="col align-self-center title m-0 py-1">{t('workflows')}</div>
            </div>
            <div id="workflow-filter-menu" className="row b-background mx-0 px-1 align-items-center">
                <div className="col p-1">
                    <Dropdown id="workflow-type-filter"
                              label={t('workflow-type')}
                              options={workflowTypes}
                              value={workflowType}
                              onChange={e => setWorkflowType(e.target.value)}/>
                </div>
                <div className="col p-1">
                    <Dropdown id="workflow-status-filter"
                              label={t('workflow-status')}
                              options={workflowStatuses}
                              value={workflowStatus}
                              onChange={e => setWorkflowStatus(e.target.value)}/>
                </div>
            </div>
            <div id="workflow-button-menu" className="row b-background mx-0 px-1 pt-1 pb-2">
                <div className="col px-1">
                    <div className="d-flex flex-row justify-content-end">
                        <Button
                            className="dashboard-buttons d-xl-block mr-1 "
                            icon="showDetail"
                            iconPosition="right"
                            label={t('expand-all')}
                            onClick={() => expandAllCards(workflowCards)}
                            id="expand-all"
                        />
                        <Button
                            className="dashboard-buttons d-xl-block mr-1 "
                            icon="hideDetail"
                            iconPosition="right"
                            label={t('collapse-all')}
                            onClick={() => collapseAllCards(workflowCards)}
                            id="collapse-all"
                        />
                        <Button
                            className="dashboard-buttons d-xl-block"
                            icon="refresh"
                            iconPosition="right"
                            onClick={() => refresh()}
                            id="refresh"
                        />
                    </div>
                </div>
            </div>
            <RenderWorkflowCards
                loading={loading}
                errorMessage={errorMessage}
                workflowCards={workflowCards}
                toggleExpand={toggleExpand}
            />
        </div>
    );

}