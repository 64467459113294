import React, {useEffect} from 'react';
import {PageType} from "src/common/enums";
import {PageProps} from "./page-interface";
import { useTranslation } from "react-i18next";

/**
 * Renders Default Error Page
 * @param props {PageProps}
 */
function DefaultErrorPage(props: PageProps) {
    const { t } = useTranslation()

    useEffect(() => {
        props.setActivePage(PageType.DEFAULT_ERROR_PAGE);
    }, []);

    return (
        <div className="container-fluid">
            <h1>{t('404-page-not-found-error')}</h1>
        </div>
    );
}
export default DefaultErrorPage;