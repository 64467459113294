import {AFTX_MENU_ENDPOINT, REGION, ENTERPRISE_SCOPE} from "src/common/constants";
import { TFunction } from "i18next";
import { ValidationError } from "src/models/validation-error";
import { logger } from "src/logger";
import { TenantID } from "src/common/enums";

/**
 * Converts the {@link Date} object to the local date {@link String} format.
 *
 * @param locale the locale (e.g. en-US).
 * @param date the date to convert.
 * @return the formatted date as a {@link String}.
 */
export const convertDateToLocaleFormat = (locale: string, date?: Date): string => {
    if (!date) {
        return '';
    }

    return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

/**
 * Converts the {@link Date} object to the local date and time {@link String} format.
 *
 * @param locale the locale (e.g. en-US).
 * @param date the date to convert.
 * @return the formatted date as a {@link String}.
 */
export const convertDateTimeToLocaleFormat = (locale: string, date?: Date): string => {
    if (!date) {
        return '';
    }

    return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
}

/**
 * Converts the {@link Date} object to the locale UTC 24-hour date/time {@link String} format.
 *
 * @param locale the locale (e.g. en-US).
 * @param date the date to convert.
 * @return the formatted date as a {@link String}.
 */
export const convertDateTimeToLocaleUTCFormat = (locale: string, date: Date): string => {
    return date.toLocaleString(locale, {
        timeZone: "UTC",
        timeZoneName: "short",
        // @ts-ignore
        hourCycle: "h24"
    });
}

/**
 * Converts the {@link Date} object to a {@link String} using the PreStart Hire API format of 'yyyy-MM-dd'.
 *
 * @param date the date to convert.
 * @return the formatted date.
 */
export const convertDateToPreStartFormat = (date: Date) => {
    let day, month, year;

    day = date.getUTCDate();
    month = date.getUTCMonth() + 1;
    year = date.getUTCFullYear();

    day = day.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * Checks if the Email is valid.
 *
 * @param email The value to validate.
 * @return true if valid; otherwise, false.
 */
export const isEmailValid = (email?: string): boolean => {
    if (!email) {
        return false;
    }

    // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript/9204568#9204568
    return /^[^\s@]+?@[^\s@]+?\.[^\s@]+$/.test(email);
}

export const generateBaseliningPrincipalWorkflowArn = (siteId: string): string => {
    return `arn:aftx:${REGION}:${TenantID.AFTX}:${ENTERPRISE_SCOPE}:site/${siteId}`
}

export const generateBaseliningPermissionWorkflowArn = (siteId: string, permissionId: string): string => {
    return `arn:sox:${REGION}:${TenantID.SOX}:${permissionId}:site/${siteId}`
}

/**
 * Gets the translated i18n error message based on the data in the ValidationError object.
 *
 * @param t The i18n translation function.
 * @param error The validation error containing translationKey and placeholderValues.
 */
export const getTranslatedErrorMsg = (t: TFunction, error: ValidationError): string => {
    if (error.placeholderValues) {
        const msg = t(error.translationKey);

        // Extract all values inside curly braces into list
        //
        // Example:
        // msg = "User with First Name of {firstName} and Last Name of {lastName} for Warehouse {warehouse} is a duplicate."
        // matches = ["firstName", "lastName", "warehouse"]
        const matches = msg.match(/[^{}]+(?=})/g);

        // Check if message contains placeholder and that number of placeholders match number of placeholder values
        // Note: This is just a sanity check that should always be true
        if (matches && (matches.length == error.placeholderValues.length)) {
            // Dynamically create object to use for i18n translation function below
            //
            // Example:
            // matches = ["firstName", "lastName", "warehouse"]
            // error.placeholderValues = ["Jeff", "Bezos", "BFI1"]
            // obj = {
            //     "firstName": "'Jeff'",
            //     "lastName": "'Bezos'",
            //     "warehouse: "'BFI1'"
            // }
            let obj: { [key: string]: string } = {};
            for (let i = 0; i < error.placeholderValues.length; i++) {
                obj[matches[i]] = `'${error.placeholderValues[i]}'`;
            }

            return t(error.translationKey, obj);
        }
    }

    return t(error.translationKey);
}

/**
 * Signs out the current user.
 */
export const signOut = () => {
    logger.info("Signing out user from FMUI and redirecting to AFT-X Menu logout page.");

    // Clear FIT tokens (access_token, id_token, etc.) from session storage
    sessionStorage.clear();

    // Redirect to AFT-X Menu logout page
    window.location.assign(`${AFTX_MENU_ENDPOINT}/menu/logout`);
}
