import { Alert, Button } from "@amzn/alchemy-components-react";
import React, { useState } from "react";
import { AlertType } from "src/common/enums";
import { useTranslation } from "react-i18next";

export interface AlertBarProps {
    id?: string,
    result: AlertType,
    header: string,
    message: string | undefined,
    dismissible?: boolean,
    reset?: CallableFunction,
    buttonText?: string
    buttonLink?: string
}

/**
 * Dismissible Alert Bar displayed at the bottom of the page.
 *
 * @param props @type{AlertBarProps}
 */
export const AlertBar = (props: AlertBarProps) => {
    const {t} = useTranslation();
    const [dismissible] = useState(props.dismissible != undefined ? props.dismissible : true);

    /**
     * Sets the message to null and calls the reset callable.
     */
    const handleDismiss = () => {
        if (props.reset) {
            props.reset();
        }
    }


    // When a link is passed we create a button for the link with generic text if none is provided.
    if (props.buttonLink) {
        return (
            <>
                <Alert
                    className="fixed-bottom mb-3"
                    dismissible={dismissible}
                    type={props.result}
                    icon={props.result == AlertType.success ? "checkSolid" : "alert"}
                    icon-size="lg"
                    header={props.header}
                    onDismiss={handleDismiss}
                    id={props.id ? props.id : "fmui-alert-bar"}
                >
                    <>
                        <div className="row">{props.message}</div>
                        <div className="row justify-content-end">
                        <Button
                            className="mr-2"
                            link={props.buttonLink}
                            label={props.buttonText ? props.buttonText : t('click-here')}
                            icon="external-link"
                            iconPosition="right"
                        />
                            </div>
                    </>
                </Alert>
            </>
        )
    }
    // No button is needed, so only the message is displayed.
    else {
        return (
            <>
                <Alert
                    className="fixed-bottom mb-3"
                    dismissible={dismissible}
                    type={props.result}
                    icon={props.result == AlertType.success ? "checkSolid" : "alert"}
                    icon-size="lg"
                    header={props.header}
                    message={props.message}
                    onDismiss={handleDismiss}
                    id={props.id ? props.id : "fmui-alert-bar"}/>
            </>
        )
    }
}