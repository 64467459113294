import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Input, Modal, Spinner } from '@amzn/alchemy-components-react';
import { useTranslation } from "react-i18next";
import { AlertType, NotificationMethod, OtpRetrievalStatus, TenantID } from "src/common/enums";
import { isEmailValid } from "src/common/util";
import { BULK_INIT_MFA_REGISTRATION_MUTATION, GET_WORKFLOW_STATE_QUERY, } from "src/common/gql-operations";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
    MILLISECONDS_IN_A_SECOND,
    NETWORK_ONLY,
    POLL_WORKFLOW_STATE_INTERVAL
} from "src/common/constants";
import { BulkInitMfaRegistrationData, BulkInitMfaRegistrationVariables } from "src/models/bulk-init-mfa-registration";
import { AlertBar } from "src/components/alert-bar";
import { logger } from "src/logger";
import { OTP, OTPs } from "src/models/otp";

import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@amzn/katal-metrics";
import { GetWorkflowStateData, GetWorkflowStateVariables } from "src/models/get-workflow-state";
import { PrincipalCard } from "src/models/principal-card";
import { PrintOtpState } from "src/models/print-otp-state";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('modal', 'bulk-register-mfa'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);
const buttonsMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('buttons', additionalMetricsContext);

/**
 * Extracts the PrincipalArns, the map's keys from the Map object.
 * @param cardMap the principal cards map.
 */
const extractPrincipalArnsFromCardMap = (cardMap: Map<string, PrincipalCard>): string[] => {
    return Array.from(cardMap.keys());
};

interface BulkRegisterMfaModalProps {
    open: boolean,
    setOpen: CallableFunction,
    /**
     * The key is the principalArn related to the {@link PrincipalCard} value.
     */
    principalCards: Map<string, PrincipalCard>,
}

/**
 * Modal component used for the Bulk Register MFA process to print or email the OTPs.
 *
 * Sequence Diagram: <a href="https://plantuml.corp.amazon.com/plantuml/form/encoded.html#encoded=XPBFJW8n4CRlVOfv0OvUE908YE60Dg3XN9VPTRAbNVid19_UQIdAheANRJFvlkbylnsfNA2AEcK2l7fqGbuK5iwQW6RNAqvLrN8DUaKvsmYH_32QJBvzOvXfgbgGZbXZ7Jpi5McOnX2i4MSLZ9Adj6MBqXhUecwNXiAgLcjio0Ud0ba3-nXE5m1n7YJRUO1YdHOhAKsVk5hSznhSowLWxU3DDORe31oiQsjj3yjvl_bFdg5m9uJIeP_h8XXx4vGnpCbtMXrX4wiAlOSLVrI3Gg3sMFWKkxQs0scr9jD0pT2UNwGN0BTmDHYsPQGD0qBuYEYERBh2w76eJrW0_WMJnSEsL-cSMeDWGuAKSd2WoFl4lmzkK2g9CvliCZRywUm_lrKKu0uyLjRiV9O-MPx0KVFEwrTVxYLVo4I4xZTepC-8T8Yyw5y0">Sequence Diagram</a>
 *
 * @param props the modal properties.
 */
export const BulkRegisterMfaModal = (props: BulkRegisterMfaModalProps) => {
    logger.info(`selected Card props: ` + extractPrincipalArnsFromCardMap(props.principalCards).toString());

    const {t} = useTranslation();
    const history = useHistory();

    const [workflowId, setWorkflowId] = useState<string>("");
    const [otps, setOtps] = useState<OTPs>();
    const [otpRetrievalStatus, setOtpRetrievalStatus] = useState<OtpRetrievalStatus>(OtpRetrievalStatus.NOT_STARTED);

    const [notificationMethod, setNotificationMethod] = useState<NotificationMethod>(NotificationMethod.PRINT);
    const [email, setEmail] = useState<string>();
    const [emailStatus, setEmailStatus] = useState<string>();
    const [emailDisabled, setEmailDisabled] = useState<boolean>(false);

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    const [onCloseCalled, setOnCloseCalled] = useState<boolean>(false);
    const [callGetWorkflowStateTimeoutId, setCallGetWorkflowStateTimeoutId] = useState<NodeJS.Timeout>();

    // Calls PCS (via FMUI GraphQL back-end) to initialize MFA registration for selected principals
    // - If MFA registration has not been initialized, then new workflow started and workflowId returned
    // - If MFA registration has already been initialized, then workflowId returned for existing workflow
    const [bulkInitMfaRegistration, {}] =
        useMutation<BulkInitMfaRegistrationData, BulkInitMfaRegistrationVariables>(BULK_INIT_MFA_REGISTRATION_MUTATION, {
            variables: {
                bulkInitMfaRegistrationInput: {
                    tenantId: TenantID.AFTX,
                    principalArns: extractPrincipalArnsFromCardMap(props.principalCards),
                    notificationMethod: notificationMethod,
                    email: email,
                }
            },
            onCompleted: data => {
                logger.info("Retrieved data from bulkInitMfaRegistration.", data);
                graphqlClientMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration.SUCCESS', 1);
                if (notificationMethod === NotificationMethod.EMAIL) {
                    showSuccessAlert(
                        t('bulk-init-mfa-registration-success-header'),
                        t('bulk-init-mfa-registration-email-success-msg', {email: email})
                    );
                    setTimeout(() => props.setOpen(false), 7 * MILLISECONDS_IN_A_SECOND);
                } else {
                    setWorkflowId(data.bulkInitMfaRegistration.workflowId);
                }
            },
            onError: error => {
                logger.error("Call to bulkInitMfaRegistration failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration.ERROR', 1);
                showErrorAlert(t('bulk-init-mfa-registration-bulk-init-failed'), error.message);
                setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
            }
        });

    // Calls PCS (via FMUI GraphQL back-end) to get workflow state by workflowId.
    // - If OTPs are not found inside workflowStateData, then repeat this request in n seconds
    // - If OTP found, then stop polling and redirect the user to the print OTPs page.
    const [getWorkflowState, {}] =
        useLazyQuery<GetWorkflowStateData, GetWorkflowStateVariables>(GET_WORKFLOW_STATE_QUERY, {
            fetchPolicy: NETWORK_ONLY, // don't cache
            notifyOnNetworkStatusChange: true, // necessary for onCompleted to be called after every poll
            onCompleted: data => {
                logger.info(`Retrieved data for workflowId ${data.getWorkflowState.workflowDetails.workflowId} from getWorkflowState.`);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-workflow-state.SUCCESS', 1);
                const workflowDetails = data.getWorkflowState.workflowDetails;
                const workflowStateData = workflowDetails.workflowStateData;
                const workflowState = workflowDetails.workflowState;

                // null, undefined, empty string are all false in JS
                // https://developer.mozilla.org/en-US/docs/Glossary/Falsy
                if (workflowStateData) {
                    // @ts-ignore
                    const workflowStateDataJson = JSON.parse(workflowStateData);
                    const otpsJsonString = workflowStateDataJson["otps"];

                    // https://developer.mozilla.org/en-US/docs/Glossary/Truthy
                    if (otpsJsonString) {
                        const otpsValue = parseOtpsFromJsonString(otpsJsonString);
                        logger.info("Retrieved OTPs from getWorkflowState: ", otpsValue);
                        setOtps(otpsValue);
                        setOtpRetrievalStatus(OtpRetrievalStatus.COMPLETED);
                    } else if (workflowState !== "active") {
                        logger.error("Bulk MFA workflow failed or completed without storing otps. Stop polling and show error alert.");
                        graphqlClientMetricsPublisher.publishCounterMonitor('bulk-mfa-registration.FAILURE', 1);
                        showErrorAlert(t('bulk-mfa-workflow-not-active-but-no-otps'), t('please-refer-to-workflows-page'));
                        setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
                    } else {
                        logger.info("OTPs are still being generated. Continuing to poll for the otps...");
                        scheduleCallGetWorkflowState();
                    }
                } else {
                    logger.error("Empty workflowStateData. Something is wrong with this bulk mfa workflow.");
                    graphqlClientMetricsPublisher.publishCounterMonitor('bulk-mfa-registration.ERROR', 1);
                    showErrorAlert(t('bulk-mfa-workflow-empty-data'), t('please-refer-to-workflows-page'));
                    setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
                }
            },
            onError: error => {
                logger.error("Call to getWorkflowState failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-workflow-state.ERROR', 1);
                showErrorAlert(t('bulk-init-mfa-registration-get-workflow-state-failed'), error.message);
                setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
            }
        });

    useEffect(() => {
        // Check if modal closed
        if (onCloseCalled) {
            // Check if another getWorkflowState request is scheduled
            if (callGetWorkflowStateTimeoutId) {
                logger.info(`Canceled scheduled call to getWorkflowState for `
                    + `timeoutId: ${callGetWorkflowStateTimeoutId} and `
                    + `principalArns: ${extractPrincipalArnsFromCardMap(props.principalCards)}`);
                clearTimeout(callGetWorkflowStateTimeoutId);
            }

            resetProps();
        }
    }, [onCloseCalled]);

    /**
     * This function is used for the initial getWorkflowState call to get the OTPs.
     */
    useEffect(() => {
        if (workflowId !== "") {
            setOtpRetrievalStatus(OtpRetrievalStatus.IN_PROGRESS);
            callGetWorkflowState();
        }
    }, [workflowId]);


    /**
     * This function is used to redirect to the Print OTPs page once we have the OTPs.
     * It is called after every poll count change.
     *
     * - If the OTPs are available, then stop polling and redirect to the Print OTPs page.
     * - If the OTPs are not available, and we are still in process schedule another call to get the OTPs.
     */
    useEffect(() => {
        if (otpRetrievalStatus === OtpRetrievalStatus.COMPLETED && otps != null) {
            // OTPs found, so stop polling
            clearTimeout(callGetWorkflowStateTimeoutId);
            setCallGetWorkflowStateTimeoutId(undefined);

            // Redirect to the Print OTP page
            buttonsMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration-print-otp.REDIRECT', 1);
            props.setOpen(false);
            setTimeout(() =>  redirectToPrintOtpPage(otps), 0);
        } else if (otpRetrievalStatus === OtpRetrievalStatus.FAILED) {
            // OTPs not found, so stop polling
            clearTimeout(callGetWorkflowStateTimeoutId);
            setCallGetWorkflowStateTimeoutId(undefined);
        }

    }, [otpRetrievalStatus]);

    /**
     * Resets the modal properties (this is necessary since the modal can be re-used while on the User's page).
     */
    const resetProps = () => {
        setCallGetWorkflowStateTimeoutId(undefined);

        setWorkflowId("");
        setOtps(undefined);
        setOtpRetrievalStatus(OtpRetrievalStatus.NOT_STARTED);

        setNotificationMethod(NotificationMethod.PRINT);  // Default

        setEmail(undefined);
        setEmailStatus(undefined);
        setEmailDisabled(false);

        resetAlertBar();

        if (props.open) {
            // Set to false so modal can be opened again
            // Note: This triggers onClose again which is why onCloseCalled hook is needed to ignore this dup event
            props.setOpen(false);
        }
    }

    /**
     * Calls to Bulk Initialize MFA registration.
     */
    const callBulkInitMfaRegistration = async () => {
        logger.info("Calling bulkInitMfaRegistration for principalArns: "
            + extractPrincipalArnsFromCardMap(props.principalCards));
        graphqlClientMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration.INVOCATION', 1);
        // To make sure it is called after the state is a refresh
        setTimeout(() =>  bulkInitMfaRegistration(), 0);
    }

    /**
     * Calls to get the workflow state by the Workflow Id.
     */
    const callGetWorkflowState = () => {
        logger.info("Calling getWorkflowState for workflowId: " + workflowId);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-workflow-state.INVOCATION', 1);
        getWorkflowState({
            variables: {
                getWorkflowStateInput: {
                    tenantId: TenantID.AFTX,
                    workflowId: workflowId
                }
            },
        });
    }

    /**
     * Schedule a call to get workflow state in 5 seconds.
     */
    const scheduleCallGetWorkflowState = () => {
        // Check OTP retrieval status again in 5 seconds
        const timeoutId = setTimeout(() => {
            callGetWorkflowState();
        }, POLL_WORKFLOW_STATE_INTERVAL);
        setCallGetWorkflowStateTimeoutId(timeoutId);
        logger.info(`Scheduled getWorkflowState call for timeoutId: ${timeoutId} and workflowId: ${workflowId}`);
    }

    /**
     * Prints the OTP.
     */
    const printOtp = async () => {
        setNotificationMethod(NotificationMethod.PRINT);
        buttonsMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration-print-otp.CLICKS', 1);

        // When the workflowId is returned we use the workflowId State to kick off fetching the otps
        await callBulkInitMfaRegistration();
    }

    /**
     * Emails the OTP (if the provided email is valid).
     */
    const emailOtp = async () => {
        setNotificationMethod(NotificationMethod.EMAIL);
        buttonsMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration-email-otp.CLICKS', 1);

        if (!isEmailValid(email)) {
            setEmailStatus(t("email-invalid-status"));
            return;
        }

        // When the workflowId is returned we use the workflowId State to kick off fetching the otps
        await callBulkInitMfaRegistration();
    }

    const redirectToPrintOtpPage = (otps: OTPs) => {
        let otpsState: PrintOtpState[];
        otpsState = Object.entries(otps).map(([principalArn, otp]) => {
            const fullName = props.principalCards.get(principalArn);
            if (fullName == undefined) {
                logger.warn(`Unable to find ${principalArn} in the principalCards prop: ${extractPrincipalArnsFromCardMap(props.principalCards)}`);
                buttonsMetricsPublisher.publishCounterMonitor('bulk-init-mfa-registration-print-otp.MISSING_PRINCIPAL_ARN', 1)
            }
            return ({
                principalArn: principalArn,
                fullName: props.principalCards.get(principalArn)?.fullName as string,
                otp: otp.value,
                otpCreationDate: otp.creationDate,
                otpExpirationDate: otp.expirationDate,
            })
        });

        history.push({
            pathname: '/principal/print-otp',
            state: {
                otps: otpsState
            }
        });
    }

    const renderModalDescription = () => {

        if (otpRetrievalStatus == OtpRetrievalStatus.NOT_STARTED) {
            return (
                <p>
                    {t('bulk-init-mfa-registration-description', {
                        numberSelected: props.principalCards.size,
                    })}
                </p>
            )
        } else if (otpRetrievalStatus == OtpRetrievalStatus.IN_PROGRESS) {
            return (
                <>
                    <p>{t('bulk-init-mfa-registration-retrieving-otps')}</p>
                    <Spinner id="bulk-init-mfa-registration-otp-status-spinner"
                             className="ml-1 justify-content-center"
                             size="2"
                             label={t('bulk-init-mfa-registration-retrieving-otps-spinner')}
                             label-position="end"
                    />
                </>
            )
        } else if (otpRetrievalStatus == OtpRetrievalStatus.COMPLETED) {
            return(
                <>
                    <p>{t('bulk-init-mfa-registration-otp-retrieved-redirecting')}</p>
                    <Spinner id="bulk-init-mfa-registration-otp-status-spinner"
                             className="ml-1 justify-content-center"
                             size="2"
                    />
                </>
            )
        } else if (otpRetrievalStatus == OtpRetrievalStatus.FAILED) {
            return (
                <>
                    <p>{t('bulk-init-mfa-registration-get-workflow-state-failed')}</p>
                </>
            )
        }
    }

    const parseOtpsFromJsonString = (optsJsonString : string): OTPs | undefined => {
        // Parse JSON string
        logger.info(`Parsing opts Json string: ${optsJsonString}`);
        const parsedData: { [key: string]: any } = JSON.parse(optsJsonString);

        // Convert to OTPs structure
        const otps: OTPs = {};

        // Iterate through the parsed data and convert each OTP entry
        for (const key in parsedData) {
            if (parsedData.hasOwnProperty(key)) {
                const otpData = parsedData[key];
                const otp: OTP = {
                    value: otpData.otp,
                    creationDate: new Date(Number(otpData.otpCreationDate)),
                    expirationDate: new Date(Number(otpData.optExpirationDate)),
                    used: otpData.optExpirationDate === "-1" // -1 exp. date means that OTP marked as used in FIT STS service
                };
                otps[key] = otp;
            }
        }
        return otps;
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    return (
        <Modal id="bulk-register-mfa-modal"
               className="mb-1"
               header={t('bulk-init-mfa-registration-title')}
               open={props.open}
               onOpen={() => {
                   setOnCloseCalled(false);
               }}
               onClose={() => {
                   setOnCloseCalled(true);
               }}
        >
            <div className="container-fluid">
                <div className="row">
                    <div className="col d-inline" id="bulk-register-mfa-modal-description">
                        {renderModalDescription()}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input id="bulk-mfa-email-input"
                               label={t('email')}
                               placeholder="some-email@example.com"
                               helpText={t('email-input-help')}
                               name="email"
                               type="email"
                               value={email}
                               onInput={e => {
                                   setEmail(e.target.value)
                               }}
                               status={isEmailValid(email) ? '' : emailStatus}
                               disabled={emailDisabled}
                        />
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <Button id="bulk-mfa-email-button"
                            className="mx-1"
                            size="lg"
                            label={t('email')}
                            variant="action"
                            onClick={emailOtp}
                            disabled={!isEmailValid(email)}
                    />
                    <Button id="bulk-mfa-print-button"
                            className="mx-1"
                            size="lg"
                            label={t('print')}
                            variant="action"
                            onClick={printOtp}
                    />
                </div>
            </div>
            {alertBarMsg &&
                <AlertBar
                    id="bulk-register-mfa-modal-alert-bar"
                    result={alertBarType!}
                    dismissible={AlertType.error !== alertBarType}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </Modal>
    )
}