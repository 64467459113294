import { getApolloClient } from "src/clients/apollo-client";
import { GET_PRINCIPAL_SCOPES_QUERY, LIST_SCOPES_QUERY } from "src/common/gql-operations";
import { NETWORK_ONLY } from "src/common/constants";
import { TenantID, UserType } from "src/common/enums";
import { logger } from "src/logger";

import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@amzn/katal-metrics";

const cloudWatchDimensions = [new KatalMetrics.Metric.String('provider', 'scopes-provider')];
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

/**
 * Calls to get all the active scopes for a principal.
 *
 * @param principalArn The ARN of the principal for which to get scopes.
 * @return list of scopes to which principal has access or an empty list if an error occurs.
 */
export const getPrincipalScopes = async (principalArn: string): Promise<string[]> => {
    try {
        logger.info(`Calling getPrincipalScopes for principalArn: ${principalArn}`);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.INVOCATION', 1);

        const result = await getApolloClient().query({
            query: GET_PRINCIPAL_SCOPES_QUERY,
            fetchPolicy: NETWORK_ONLY, // don't cache
            variables: {
                getPrincipalScopesInput: {
                    tenantId: TenantID.AFTX,
                    principalArn: principalArn
                }
            },
        });

        logger.info(`Retrieved data from getPrincipalScopes for principalArn: ${principalArn}`, result.data);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.SUCCESS', 1);

        // Example data.getPrincipalScopes.scopes value:
        // [BFI1:ASSOCIATE, BFI2:ASSOCIATE, enterprise:SYSTEM_ADMIN]
        const scopeAndUserTypeArr: string[] = result.data.getPrincipalScopes.scopes;

        // Example scopes after transformation:
        // [BFI1, BFI2, enterprise]
        const scopes = scopeAndUserTypeArr.map(value => value.split(":")[0]);

        // Example userTypes after transformation:
        // [ASSOCIATE, ASSOCIATE, SYSTEM_ADMIN]
        const userTypes = scopeAndUserTypeArr.map(value => value.split(":")[1]);

        if (userTypes.includes(UserType.SYSTEM_ADMIN)) {
            const listScopesResult = await getApolloClient().query({
                query: LIST_SCOPES_QUERY,
                fetchPolicy: NETWORK_ONLY, // don't cache
                variables: {
                    listScopesInput: {
                        tenantId: TenantID.AFTX,
                        active: true
                    }
                },
            });
            const SCOPES_ONBOARDED_TO_ULM: string[] = listScopesResult.data.listScopes.scopes;
            logger.info(`User with principalArn of ${principalArn} is a ${UserType.SYSTEM_ADMIN} ` +
                        `and has access to all scopes: ${SCOPES_ONBOARDED_TO_ULM}`);
            return SCOPES_ONBOARDED_TO_ULM;
        } else {
            logger.info(`User with principalArn of ${principalArn} has access to the following scopes: ${scopes}`);
            return scopes;
        }
    } catch (e) {
        const errMsg = `Failed to get scopes for principalArn ${principalArn}`;
        logger.error(errMsg, e as Error);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.ERROR', 1);

        return [];
    }
}