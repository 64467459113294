import Excel from "exceljs";
import { MILLISECONDS_IN_A_SECOND, MIME_TYPE_XLSX } from "src/common/constants";
import {Principal} from "src/models/principal";

/**
 * Loads the specified Excel file from the /assets directory.
 *
 * @param fileName The Excel file to load from the assets' directory.
 * @return {@link Excel.Workbook} The loaded Excel workbook.
 */
export const loadExcelFile = async (fileName: string, ) : Promise<Excel.Workbook> => {
    const templateFilePath = `/assets/${fileName}`;
    const response = await fetch(templateFilePath);
    const bufferIn = await response.arrayBuffer();
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(bufferIn);
    return workbook;
}

/**
 * Downloads the Excel Workbook.
 *
 * @param workbook {@link Excel.Workbook} To be downloaded.
 * @param fileName The name given to the file when it is downloaded by the browser.
 */
export const downloadExcelFile = async (workbook: Excel.Workbook, fileName: string): Promise<void> => {
    const bufferOut = await workbook.xlsx.writeBuffer();
    const data = new Blob([bufferOut], {type: MIME_TYPE_XLSX});
    const hiddenDownloadLink = document.createElement('a');
    hiddenDownloadLink.setAttribute('hidden', 'true');
    hiddenDownloadLink.setAttribute('target', '_blank');
    hiddenDownloadLink.href = URL.createObjectURL(data);
    hiddenDownloadLink.download = fileName;
    hiddenDownloadLink.click();
}


/**
 * Converts the timestamp from Excel into the YYYY-MM-DD format our APIs require.
 * @param timeStamp The Excel datetime timestamp.
 */
export const convertLocalTimeStampString = (timeStamp: string): string => {
    try {
        return new Date(timeStamp).toISOString().slice(0, 10);
    } catch (e) {
        // Return the original value as is so that the validation error is understandable to the user.
        return timeStamp
    }
}

/**
 * Converts Unix timestamp into a {@link Date} which Excel can use.
 *
 * When the Date object is used in Excel it keeps the browser's timezone.
 * It could potentially cause unexpected results.
 * The positive is it allows for date manipulation which are used by excel power users
 * which would be lost if the date is a String in Excel.
 */
export const convertUnixTimeStampToDate = (timeStamp: number | null | undefined): Date | null => {
    if (timeStamp == null) {
        return null;
    }

    // Dates can be returned in either milliseconds or seconds from PCS
    const date = `${timeStamp}`.length === 13 ? new Date(timeStamp) : new Date(timeStamp * MILLISECONDS_IN_A_SECOND);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

/**
 * Match to a list case-insensitively
 */
export const convertToListValue = (value: string, values: string[]) => {
    const found = values.find(v => v.toUpperCase() === value.toUpperCase());
    if (found === undefined){
        // Return the original value as is so that the validation error is understandable to the user.
        return value;
    } else {
        return found;
    }
}

/**
 * Make it so the values can be passed in and validated case-insensitively.
 */
export const convertOptionValueToValue = (inputValue: string, options: {label: string, value: string}[]) => {
    const option = options.find(({value}) => value.toUpperCase() === inputValue.toUpperCase());
    if (option === undefined) {
        // Return the original value as is so that the validation error is understandable to the user.
        return inputValue;
    } else {
        return option.value;
    }
}

export const convertOptionLabelToValue = (inputLabel: string, options: { label: string, value: string }[]) => {
    const option = options.find(({label}) => label.toUpperCase() === inputLabel.toUpperCase());
    if (option === undefined) {
        // Return the original value as is so that the validation error is understandable to the user.
        return inputLabel;
    } else {
        return option.value;
    }
}
/**
 * Converts letters into Hexavigesimal. Hexavigesimal is the numbering format of the Excel Column Letters.
 * The function converts the Excel Column Letter into the Excel column's number, a 1-based index.
 *
 * https://stackoverflow.com/a/12699230/7838574
 * @param letter The Excel column letter.
 */
export const columnLetterToNumber = (letter: string): number => {
    let integer = 0;
    const letters = [...letter];

    for (let i = letters.length - 1; i >= 0; i--) {
        integer += (letters[i].charCodeAt(0) - 64) * (Math.pow(26, letters.length - (i + 1)));
    }

    return integer;
}