import React from 'react'
import {Checkbox, Icon} from '@amzn/alchemy-components-react'
import { CardItem } from 'src/components/cards/card-item'

/**
 * Input parameters required to create PrincipalSummaryCard component.
 */
interface PrincipalSummaryCardProps {
  /**
   * The unique key principalArn for the card used to determine what card to expand/collapse or select/deselect.
   */
  cardKey: string

  /**
   * Check box state for bulk actions.
   */
  selected: boolean

  /**
   * Header to be displayed.
   */
  summaryCardHeader: string

  /**
   * A list of Items to be displayed for summary card.
   */
  summaryCardItems: CardItem[]

  /**
   * Element used to display what actions can be performed against the item
   */
  actionComponent: React.ReactNode

  /**
   * Function used to expand or collapse the card
   */
  toggleExpand: (cardKey: string) => void

  /**
   * Function used to select or deselect the card
   */
  toggleSelect: (cardKey: string) => void

  /**
   * Flag used to determine if the card is expanded or collapsed (optional)
   */
  expanded: boolean;
}

/**
 * Component for creating Principal Summary card for list page.
 */
export const PrincipalSummaryCard = (props: PrincipalSummaryCardProps) => {

  const renderSummaryCardItem = (summaryCardItems: CardItem[]) => {
    return summaryCardItems.map((summaryCardItem, index) => {
      return (
        <div className="col-lg-4 overflow-auto" key={index}>
          <p className="attribute-name">{summaryCardItem.attributeName}</p>
          {Array.isArray(summaryCardItem.attributeValue) ? (
            <p>
              {summaryCardItem.attributeValue.map((value: any, index: number) => (
                <span key={index}>
                  {value}
                  <br />
                </span>
              ))}
            </p>
          ) : (
            <p>{summaryCardItem.attributeValue}</p>
          )}
        </div>
      )
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    //Using CTRL key to expand
    if (event.key === 'Control') {
      props.toggleExpand(props.cardKey!);
    }
  }

  return (
    <>
      <div className={`col mt-1 px-2 py-1 b-background`} id="summary-card">
        <div className="row subheading align-items-center mb-0" id="summary-card-header">
          <Checkbox
              className="pl-2"
              id={`multiselect-${props.cardKey}`}
              size="lg"
              checked={props.selected}
              onInput={() => props.toggleSelect(props.cardKey)}
              tabIndex={1}
          />
          <div
              className="col"
              id="summary-card-title"
              onClick={() => props.toggleExpand(props.cardKey)}
          >
            <div
                className="summary-card-expand-icon"
                id="summary-card-expand-icon"
                role="button"
                onKeyDown={(event) => handleKeyDown(event)}
                tabIndex={0}
              >
                <Icon className="expandable-icon" icon={props.expanded ? "chevronDown" : "chevronRight"} />
              </div>

            {props.summaryCardHeader}

          </div>
          <div className="col-auto" id="summary-card-action">
            {props.actionComponent}
          </div>
        </div>
        {props.expanded &&
          <div className="row flex-wrap-reverse justify-content-between" id="summary-card-data">
            <div className="col-lg-12 row" id="summary-card-items">
              {renderSummaryCardItem(props.summaryCardItems)}
            </div>
          </div>
        }
      </div>
    </>
  )
}
