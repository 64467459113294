import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { PageProps } from "src/pages/page-interface";
import { BreadCrumbItem, BreadCrumbs } from "src/components/breadcrumb";
import { PageType } from "src/common/enums";
// @ts-ignore
import bwipjs from 'bwip-js';
import { useReactToPrint } from "react-to-print";
import { logger } from "src/logger";
import { convertDateTimeToLocaleUTCFormat } from "src/common/util";
import { Button } from '@amzn/alchemy-components-react';
import { PrintOtpsState, PrintOtpState } from "src/models/print-otp-state";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";


const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'print-otp'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const clientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('print-otp', additionalMetricsContext);


export const PrintOtp = (props: PageProps) => {
    const {t} = useTranslation();

    const locale = document.documentElement.lang;   // e.g. en-US
    const location = useLocation<PrintOtpsState>();

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        // Print OTP is a subpage under Users page, so set Users nav item as active page
        props.setActivePage(PageType.PRINCIPAL_LIST);

        if (!location.state) {
            logger.error("Failed to load 'Print OTP' page since state is missing!");

            // Redirect to 'Users' page
            window.location.replace('/principal');
        } else {
            location.state.otps.forEach((otpState: { otp: any; principalArn: any; }) => {
                if (otpState.otp) {
                    bwipjs.toCanvas(`otp-barcode-canvas-${otpState.otp}`, {
                        bcid: 'datamatrix',        // Barcode type
                        text: otpState.otp,        // Text to encode
                        scale: 5,                  // Scaling factor
                    });
                }
            });
        }
    }, []);

    const renderOtp = (otpState: PrintOtpState) => {
        const otpCreationDate: string =
            convertDateTimeToLocaleUTCFormat(locale, otpState.otpCreationDate);
        const otpExpirationDate: string =
            convertDateTimeToLocaleUTCFormat(locale, otpState.otpExpirationDate);

        return (
            <div id={`otp-page-${otpState.otp}`} key={otpState.otp}
                 className="print-otp-page">
                <div className="row mb-4">
                    <div className="col-8 mx-auto my-auto">
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column pr-1">
                                <b>{t('created')} </b>
                                <b>{t('expires')} </b>
                            </div>
                            <div className="d-flex flex-column text-right">
                                <span id={`otp-creation-date-${otpState.otp}`}>{otpCreationDate}</span>
                                <span id={`otp-expiration-date-${otpState.otp}`}>{otpExpirationDate}</span>
                            </div>
                        </div>
                        <div className="text-center mb-2">
                            <b>{t('user-name')}:</b>&nbsp;
                            <span id={`user-name-${otpState.otp}`}>{otpState.fullName}</span>
                        </div>
                        <div className="text-center">
                            <canvas id={`otp-barcode-canvas-${otpState.otp}`}/>
                        </div>
                        <div className="text-center mb-4">
                            <span id={`otp-value-${otpState.otp}`}>{otpState.otp}</span>
                        </div>
                        <div className="mb-1">
                            <b>{t('mfa-registration-instructions-header')}</b>
                        </div>
                        <div>
                            <ol>
                                {/* TODO: remove `-alpha` from translation key when the finalized instructions are provided. */}
                                <li>{t('mfa-registration-instruction-step-1-alpha')}</li>
                                <li>{t('mfa-registration-instruction-step-2-alpha')}</li>
                                <li>{t('mfa-registration-instruction-step-3-alpha')}</li>
                                <li>{t('mfa-registration-instruction-step-4-alpha')}</li>
                            </ol>
                        </div>
                        <div>
                            <p>{t('mfa-registration-instructions-footer-alpha')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderOtps = () => {
        if (location.state) {
            return location.state.otps.map((otpStage: PrintOtpState) => {
                return renderOtp(otpStage)
            });
        } else {
            clientMetricsPublisher.publishCounterMonitor('render-otps.ERROR', 1);
            logger.error("Failed to render OTPs since state is missing!");
            return (<p>{t('print-otp-error-no-otp-data')}</p>)
        }
    };

    const getBreadcrumbs = (): BreadCrumbItem[] => {
        const breadcrumbs: BreadCrumbItem[] = [];

        breadcrumbs.push({tag: t('users'), path: "/principal"});
        // breadcrumbs.push({tag: fullName}); // TODO: Add a path to this Breadcrumb that navigates the user back to the Users page with the selected User accordion opened
        breadcrumbs.push({tag: t('print-otp')});

        return breadcrumbs;
    };

    const renderPage = () => {
        if (location.state) {
            return (
                <div id="print-otp-pages"
                     className="container-fluid"
                     ref={componentRef}>
                    <div id="bread-crumbs-row" className="row">
                        <BreadCrumbs breadcrumbItems={getBreadcrumbs()}/>
                    </div>
                    <div id="print-instructions" className="row mx-0 mb-4-5 py-1 b-background text-center">
                        <div className="col-12 mb-2">
                            <div className="font-italic d-inline">
                                {t('please-print-and-hand-to-associates')}
                            </div>
                        </div>
                        <div id="print-button" className="col-12">
                            <Button id="bulk-mfa-print-otp-button"
                                    className="mx-1"
                                    size="lg"
                                    label={t('print')}
                                    variant="action"
                                    onClick={handlePrint}/>
                        </div>
                    </div>
                    {renderOtps()}
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        renderPage()
    );
}