import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup } from "@amzn/alchemy-components-react";
import { logger } from "src/logger";
import { BreadCrumbs } from 'src/components/breadcrumb';
import {
    PageType,
    OnboardType,
    WorkflowState,
    WorkflowStatus,
    WorkflowType,
    TenantID,
    UserType
} from "src/common/enums";
import { PageProps } from "src/pages/page-interface";
import { RenderWorkflowCards } from "src/pages/workflows/render-workflow-cards";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { LIST_WORKFLOWS_QUERY } from "src/common/gql-operations";
import { BULK_ONBOARD_URL, ENTERPRISE_SCOPE, NETWORK_ONLY, SINGLE_ONBOARD_URL } from "src/common/constants";
import { WorkflowCard } from "src/models/workflow-card";
import { WorkflowDetails } from "src/models/workflow-details";
import { Context, Metric } from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { convertDateTimeToLocaleFormat } from "src/common/util";
import { CardItem } from "src/components/cards/card-item";
import { convertUnixTimeStampToDate, downloadExcelFile, loadExcelFile } from "src/common/excel-util";

const cloudWatchDimensions = [
    new Metric.String('page', 'onboard'),
]

// @ts-ignore
const additionalMetricsContext = new Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);


export const Onboard = (props: PageProps) => {
    const {t} = useTranslation();
    const locale = document.documentElement.lang;   // e.g. en-US
    const history = useHistory();

    // Using 15 days to avoid timezone issues when we tell the users it is the past two weeks of data
    let fifteenDaysAgoDate = new Date();
    fifteenDaysAgoDate.setDate(fifteenDaysAgoDate.getDate() - 15);

    // Associate related States
    const [associateOnboardWorkflowCards, setAssociateOnboardWorkflowCards] = useState<Map<string, WorkflowCard>>(new Map());
    const [associateOnboardingWorkflowLoading, setAssociateOnboardingWorkflowLoading] = useState(true);
    const [associateOnboardingErrorMessage, setAssociateOnboardingErrorMessage] = useState<string | undefined>();

    // User Admin related States
    const [userAdminOnboardWorkflowCards, setUserAdminOnboardWorkflowCards] = useState<Map<string, WorkflowCard>>(new Map());
    const [userAdminOnboardingWorkflowLoading, setUserAdminOnboardingWorkflowLoading] = useState(true);
    const [userAdminOnboardingErrorMessage, setUserAdminOnboardingErrorMessage] = useState<string | undefined>();

    const [loading, setLoading] = useState(true);
    const [onboardingWorkflowCards, setOnboardingWorkflowCards] = useState<Map<string, WorkflowCard>>(new Map());
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    /**
     * Helper function to set the list of workflows
     *
     * @param data The successful response object.
     * @param setWorkflowTypeCards The state function update hook for the workflow type.
     * @param setWorkflowTypeLoading The state function update hook for the loading state of the request.
     * @param setWorkflowTypeErrorMessage The state function update hook for the error message.
     */
    const listWorkflowsOnCompletion = (data: any,
                                       setWorkflowTypeCards: React.Dispatch<React.SetStateAction<Map<string, WorkflowCard>>>,
                                       setWorkflowTypeLoading: React.Dispatch<React.SetStateAction<boolean>>,
                                       setWorkflowTypeErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>) => {
        logger.info(`Retrieved ${data.listWorkflows.workflowDetails.length} records from listWorkflows.`);
        graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.SUCCESS', 1);

        const workflowCards = new Map<string, WorkflowCard>();
        let workflows: WorkflowDetails[] = data.listWorkflows.workflowDetails;

        // Filter to only display the past 2 weeks
        workflows = workflows.filter(wf => wf.creationDate >= fifteenDaysAgoDate.getTime())
        logger.info(`Removed ${data.listWorkflows.workflowDetails.length - workflows.length} workflow(s) because they are older than 15 days.`);

        for (const workflow of workflows) {
            workflowCards.set(workflow.workflowId, {
                items: getCardItems(workflow),
                expanded: false,
                header: getHeader(workflow),
                state: workflow.workflowState as WorkflowState
            });
        }

        setWorkflowTypeCards(workflowCards);
        setWorkflowTypeErrorMessage(undefined);
        setWorkflowTypeLoading(false);
    }

    /**
     * Helper Function to set list workflow errors
     *
     * @param error The {@link ApolloError} response object
     * @param setWorkflowTypeCards The state function update hook for the workflow type.
     * @param setWorkflowTypeLoading The state function update hook for the loading state of the request.
     * @param setWorkflowTypeErrorMessage The state function update hook for the error message.
     */
    const listWorkflowOnError = (error: ApolloError,
                                 setWorkflowTypeCards: React.Dispatch<React.SetStateAction<Map<string, WorkflowCard>>>,
                                 setWorkflowTypeLoading: React.Dispatch<React.SetStateAction<boolean>>,
                                 setWorkflowTypeErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>) => {
        logger.error("Call to listWorkflows failed!", error);
        graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.ERROR', 1);
        setWorkflowTypeCards(new Map());
        setWorkflowTypeErrorMessage(error.message);
        setWorkflowTypeLoading(false);
    }

    const [listAssociateOnboardWorkflows] = useLazyQuery(LIST_WORKFLOWS_QUERY, {
        fetchPolicy: NETWORK_ONLY, // don't cache
        onCompleted: data => {
            listWorkflowsOnCompletion(data,
                setAssociateOnboardWorkflowCards,
                setAssociateOnboardingWorkflowLoading,
                setAssociateOnboardingErrorMessage);
        },
        onError: error => {
            listWorkflowOnError(error,
                setAssociateOnboardWorkflowCards,
                setAssociateOnboardingWorkflowLoading,
                setAssociateOnboardingErrorMessage);
        }
    });

    const [listUserAdminOnboardWorkflows] = useLazyQuery(LIST_WORKFLOWS_QUERY, {
        fetchPolicy: NETWORK_ONLY, // don't cache
        onCompleted: data => {
            listWorkflowsOnCompletion(data,
                setUserAdminOnboardWorkflowCards,
                setUserAdminOnboardingWorkflowLoading,
                setUserAdminOnboardingErrorMessage);
        },
        onError: error => {
            listWorkflowOnError(error,
                setUserAdminOnboardWorkflowCards,
                setUserAdminOnboardingWorkflowLoading,
                setUserAdminOnboardingErrorMessage);
        }
    });

    /**
     * Calls to get the list of workflows.
     */
    const callListWorkflow = (workflowType: WorkflowType) => {
        logger.info(`Calling listWorkflows for type: ${workflowType}.`);
        const variables: any = {
            listWorkflowsInput: {
                tenantId: TenantID.AFTX,
                includeChildWorkflows: true,
                workflowStatus: WorkflowStatus.ALL,
                workflowType: workflowType,
            }
        };

        logger.info("Calling listWorkflows for all onboard workflow types.", variables.listWorkflowsInput);

        graphqlClientMetricsPublisher.publishCounterMonitor('list-workflows.INVOCATION', 1);
        if (workflowType === WorkflowType.AFTX_3PL_ASSOCIATE_ONBOARDING) {
            listAssociateOnboardWorkflows({
                variables: variables,
            });
        } else if (workflowType === WorkflowType.AFTX_3PL_USER_ADMIN_ONBOARDING) {
            listUserAdminOnboardWorkflows({
                variables: variables,
            });
        }
    }

    /**
     * Get the relevant onboarding workflows.
     */
    const callListWorkflows = () => {
        // TODO: Explore getting the list of onboarding workflows with one request (including bulk onboardings).
        //  Also with pagination. This implementation uses the APIs we have in place to meet the launch deadline.
        callListWorkflow(WorkflowType.AFTX_3PL_ASSOCIATE_ONBOARDING);
        callListWorkflow(WorkflowType.AFTX_3PL_USER_ADMIN_ONBOARDING);
    }

    /**
     * Refreshes the list of workflows.
     */
    const refresh = () => {
        logger.info("Refreshing list of workflows.");
        setLoading(true);
        setAssociateOnboardingWorkflowLoading(true);
        setAssociateOnboardingErrorMessage(undefined);
        setUserAdminOnboardingWorkflowLoading(true);
        setUserAdminOnboardingErrorMessage(undefined);
        callListWorkflows();
    }

    useEffect(() => {
        props.setActivePage(PageType.ONBOARD);
        callListWorkflows()
    }, []);

    /**
     * Hook to set the workflow list.
     */
    useEffect(() => {
        // TODO: explore grouping the workflows by parent workflows meaning we display what workflows were apart of an
        //  onboarding submission (bulk onboard)

        logger.info('Updating onboardingWorkflowCards');
        const loadingCompleted = !(associateOnboardingWorkflowLoading || userAdminOnboardingWorkflowLoading);
        logger.info(`loadingCompleted = ${loadingCompleted}`)
        if (loadingCompleted) {
            const entries = [
                ...associateOnboardWorkflowCards.entries(),
                ...userAdminOnboardWorkflowCards.entries(),
            ];
            entries.sort((a, b) => {
                const creationDateCardItemA = a[1].items.find(item => item.attributeName === t('creation-date'));
                const creationDateCardItemB = b[1].items.find(item => item.attributeName === t('creation-date'));
                const creationDateA = creationDateCardItemA !== undefined ? `${creationDateCardItemA.attributeValue}` : '2000-01-0 00:00:00';
                const creationDateB = creationDateCardItemB !== undefined ? `${creationDateCardItemB.attributeValue}` : '2000-01-0 00:00:00';

                // @ts-ignore
                return new Date(creationDateB) - new Date(creationDateA);
            });
            setOnboardingWorkflowCards(new Map(entries));

            // Set the error message, if any. Only one error is shown because the user action is always the same (refresh the page).
            if(associateOnboardingErrorMessage || userAdminOnboardingErrorMessage) {
                if (associateOnboardingErrorMessage) {
                    logger.error(`Error message displayed to the user when getting onboarding submissions: ${associateOnboardingErrorMessage}`);
                    setErrorMessage(associateOnboardingErrorMessage);
                } else {
                    logger.error(`Error message displayed to the user when getting onboarding submissions: ${userAdminOnboardingErrorMessage}`);
                    setErrorMessage(userAdminOnboardingErrorMessage);
                }
            }
        }

        let currentLoadingState = associateOnboardingWorkflowLoading && userAdminOnboardingWorkflowLoading;
        setLoading(currentLoadingState);
    }, [
        associateOnboardWorkflowCards,
        userAdminOnboardWorkflowCards,
        associateOnboardingErrorMessage,
        userAdminOnboardingErrorMessage,
        associateOnboardingWorkflowLoading,
        userAdminOnboardingWorkflowLoading,
    ]);

    /**
     * Download the onboarding submissions as an Excel file to make it easier for User Admins to tie
     * the failed workflows to the PII in the Excel worksheets they submitted via bulk onboarding.
     */
    const downloadOnboardingSubmissions = async () => {
        graphqlClientMetricsPublisher.publishCounterMonitor('download-excel.INVOCATION', 1);
        try {
            logger.info('Downloading onboarding submissions');
            const workbook = await loadExcelFile('onboarding-requests-list.xlsx');

            // Header
            const dataSheet = workbook.worksheets[0];
            let row = dataSheet.getRow(1);
            row.font = {bold: true};
            row.border = {bottom: {style: 'thin'}};
            row.getCell(1).value = t('warehouse') as string;
            row.getCell(2).value = t('user-type') as string;
            row.getCell(3).value = t('first-name') as string;
            row.getCell(4).value = t('last-name') as string;
            row.getCell(5).value = t('requested-date') as string;
            row.getCell(6).value = t('workflow-id') as string;
            row.getCell(7).value = t('vlookup-key') as string;
            row.getCell(8).value = t('status') as string;

            // Data
            Array.from(onboardingWorkflowCards.entries()).forEach(([workflowId, workflowCard], index) => {
                logger.info(`workflowCard = ${JSON.stringify(workflowCard)}`);
                const items = workflowCard.items;

                const getValue = (translationKey: string): string => {
                    return items.find(item => item.attributeName === t(translationKey))?.attributeValue as string;
                }
                const warehouse = getValue('warehouse');
                const userType = getValue('user-type');
                const firstName = getValue('first-name');
                const lastName = getValue('last-name');

                row = dataSheet.getRow(index + 2);
                row.getCell(1).value = warehouse;
                row.getCell(2).value = userType;
                row.getCell(3).value = firstName;
                row.getCell(4).value = lastName;
                row.getCell(5).value = convertUnixTimeStampToDate(new Date(getValue('creation-date')).getTime());  // Excel handles the date formatting
                row.getCell(6).value = workflowId;
                row.getCell(7).value = `${warehouse}${userType}${firstName}${lastName}`
                row.getCell(8).value = t(workflowCard.state);
            });

            const today = new Date().toISOString().substring(0, 10);
            await downloadExcelFile(workbook, `onboarding-requests-list-${today}.xlsx`);
            graphqlClientMetricsPublisher.publishCounterMonitor('download-excel.SUCCESS', 1);
        } catch (e) {
            logger.error(`Error when downloading onboarding submissions file: ${e}`);
            graphqlClientMetricsPublisher.publishCounterMonitor('download-excel.ERROR', 1);
        }
    }

    const redirectToSingleOrBulk = (e: any) => {
        const selection = e.target.value;

        switch (selection) {
            case OnboardType.SINGLE:
                logger.info('Redirecting to single user onboard page.');
                history.push({
                    pathname: SINGLE_ONBOARD_URL,
                });
                break;
            case OnboardType.BULK:
                logger.info('Redirecting to bulk user onboard page.');
                history.push({
                    pathname: BULK_ONBOARD_URL,
                });
                break;
        }
    }

    /**
     * Gets the header for the workflow card.
     *
     * @param workflow The workflow details from which to get the data for the header.
     */
    const getHeader = (workflow: WorkflowDetails) => {
        const workflowStateData = JSON.parse(workflow.workflowStateData!);
        const scope = workflowStateData["scope"];
        const userType = workflowStateData["userType"];
        const firstName = workflowStateData["legalName.firstName"];
        const lastName = workflowStateData["legalName.lastName"];

        return `${scope} : ${userType.toLocaleUpperCase(locale)} - ${firstName} ${lastName}`;
    }

    /**
     * Gets the list of {@link CardItem} from the {@link WorkflowDetails} object.
     *
     * @param workflow The workflow from which to get the data for the card items.
     */
    const getCardItems = (workflow: WorkflowDetails): CardItem[] => {
        const workflowStateData = JSON.parse(workflow.workflowStateData!);
        const scope = workflowStateData["scope"];
        const userType = workflowStateData["userType"];
        const firstName = workflowStateData["legalName.firstName"];
        const lastName = workflowStateData["legalName.lastName"];

        return [
            {
                attributeName: t('warehouse'),
                attributeValue: scope ? scope.toLocaleUpperCase(locale) : '',
            },
            {

                attributeName: t('user-type'),
                attributeValue: userType ? userType.toLocaleUpperCase(locale) : '',
            },
            {
                attributeName: t('current-step'),
                attributeValue: workflow.workflowCurrentStep.toLocaleUpperCase(locale),
            },
            {
                attributeName: t('creation-date'),
                attributeValue: workflow.creationDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(workflow.creationDate)) : '',
            },
            {
                attributeName: t('completion-date'),
                attributeValue: workflow.completionDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(workflow.completionDate)) : '',
            },
            {
                attributeName: t('first-name'),
                attributeValue: firstName ? firstName : ''
            },
            {
                attributeName: t('last-name'),
                attributeValue: lastName ? lastName : ''
            },
            {
                attributeName: t('workflow-id'),
                attributeValue: workflow.workflowId
            }
        ];
    }

    /**
     * Expands all workflow cards.
     *
     * @param workflowCards The workflow cards to expand.
     */
    const expandAllCards = (workflowCards: Map<string, WorkflowCard>) => {
        logger.info("Expanding all Onboarding Submission Cards.");
        for (const workflowId of workflowCards.keys()) {
            const workflowCard = workflowCards.get(workflowId)!;
            workflowCards.set(workflowId, {
                items: workflowCard.items,
                expanded: true,
                header: workflowCard.header,
                state: workflowCard.state
            });
        }

        setOnboardingWorkflowCards(new Map(workflowCards));
    }

    /**
     * Collapses all workflow cards.
     *
     * @param workflowCards The workflow cards to collapse.
     */
    const collapseAllCards = (workflowCards: Map<string, WorkflowCard>) => {
        logger.info("Collapsing all Onboarding Submission Cards.");
        for (const workflowId of workflowCards.keys()) {
            const workflowCard = workflowCards.get(workflowId)!;
            workflowCards.set(workflowId, {
                items: workflowCard.items,
                expanded: false,
                header: workflowCard.header,
                state: workflowCard.state
            });
        }

        setOnboardingWorkflowCards(new Map(workflowCards));
    }

    /**
     * Expands the workflow card if it's collapsed or collapses the workflow card if it's expanded.
     *
     * @param workflowId The workflow id of the workflow card to expand or collapse.
     */
    const toggleExpand = (workflowId: string) => {
        const workflowCard = onboardingWorkflowCards.get(workflowId)!;
        logger.info(`${workflowCard.expanded ? "Collapsing" : "Expanding"} Onboarding Submission Card for workflowId: ${workflowId}.`);
        onboardingWorkflowCards.set(workflowId, {
            items: workflowCard.items,
            expanded: !workflowCard.expanded,
            header: workflowCard.header,
            state: workflowCard.state
        });

        setOnboardingWorkflowCards(new Map(onboardingWorkflowCards));
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{tag: t('onboard'), path: '/onboard'}]}/>
            </div>
            <div className="row mx-0 mb-0-5 b-background">
                <div className="col title m-0 py-1">{t('onboard-menu')}</div>
            </div>
            <div className="b-background">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <p className="mb-0 px-2 pt-2">{t('onboard-menu-question')}</p>
                    </div>
                </div>
                <div className="row mt-0 py-2">
                    <div className="col d-flex justify-content-center">
                        <ButtonGroup
                            id="onboard-buttons"
                            onChange={redirectToSingleOrBulk}
                            buttons={[
                                {id: 'single-onboard-button', label: t('single'), value: OnboardType.SINGLE},
                                {id: 'bulk-onboard-button', label: t('bulk'), value: OnboardType.BULK}
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="row b-background mx-0 mt-2 mb-0">
                <div className="col-12 align-self-center title m-0 py-1">{t('past-two-weeks-submissions')}</div>
                <div className="col-12 align-self-center m-0 py-1">
                    {t('prior-onboard-submissions-subheader-raise-ct-ticket')}
                </div>
            </div>
            <div id="workflow-button-menu" className="row b-background mx-0 px-1 pt-1 pb-2">
                <div className="col px-1">
                    <div className="d-flex flex-row justify-content-end">
                        <Button
                            className="dashboard-buttons d-xl-block mr-1 "
                            icon="showDetail"
                            iconPosition="right"
                            label={t('expand-all')}
                            onClick={() => expandAllCards(onboardingWorkflowCards)}
                            id="expand-all"
                        />
                        <Button
                            className="dashboard-buttons d-xl-block mr-1 "
                            icon="hideDetail"
                            iconPosition="right"
                            label={t('collapse-all')}
                            onClick={() => collapseAllCards(onboardingWorkflowCards)}
                            id="collapse-all"
                        />
                        <Button
                            className="dashboard-buttons mr-1 d-none d-xl-block"
                            icon="download"
                            iconPosition="right"
                            label={t('download')}
                            onClick={downloadOnboardingSubmissions}
                            id="download-all"
                        />
                        <Button
                            className="dashboard-buttons d-xl-block"
                            icon="refresh"
                            iconPosition="right"
                            onClick={refresh}
                            id="refresh"
                        />
                    </div>
                </div>
            </div>
            <RenderWorkflowCards
                loading={loading}
                errorMessage={errorMessage}
                workflowCards={onboardingWorkflowCards}
                toggleExpand={toggleExpand}
            />
        </div>
    );
}

export default Onboard;