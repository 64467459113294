import React, { useState } from 'react'
import { Menu, Button } from '@amzn/alchemy-components-react'
import { useTranslation } from 'react-i18next'
import { BulkRegisterMfaModalHooks } from "src/models/bulk-register-mfa-modal-hooks";

/**
 * Input parameters required to create Bulk Action component.
 */
interface BulkActionProps {
  /**
   * Count of the principals (users) selected.
   */
  principalArnsCount: number

  /**
   * The hooks for the Bulk Register MFA modal.
   */
  bulkRegisterMfaModalHooks: BulkRegisterMfaModalHooks

}

/**
 * Component to create Bulk Action Dropdown.
 */
export const BulkAction = (bulkActionProps: BulkActionProps) => {
  const { t } = useTranslation()

  const [menuIcon, setMenuIcon] = useState('caretDown')

  const initializeBulkRegisterMfaModal = () => {
    bulkActionProps.bulkRegisterMfaModalHooks.setOpen(true);
  }

  const registerMfa = {
    label: t('register-mfa'),
    action: initializeBulkRegisterMfaModal
  }

  const actionMenuOptions = []
  actionMenuOptions.push(registerMfa)

  return (
    <Menu
      className="dashboard-buttons mr-1 d-block summary-card-action"
      id="bulk-action-menu"
      options={actionMenuOptions}
      onClose={() => setMenuIcon('caretDown')}
      onOpen={() => setMenuIcon('caretUp')}
    >
      <span slot="trigger">
        <Button
            icon={menuIcon}
            iconPosition="right"
            disabled={bulkActionProps.principalArnsCount === 0}>
          {t('bulk-action', {
            "numberSelected": bulkActionProps.principalArnsCount
          })}
        </Button>
      </span>
    </Menu>
  )
}
