import React, { useEffect, useState } from "react";
import { PageProps } from "src/pages/page-interface";
import { useLocation } from "react-router-dom";
import { Button, Modal, RadioButtons } from '@amzn/alchemy-components-react'
import {
    AlertType,
    BaseliningPermissionOperation,
    PageType,
    TenantID,
    WorkflowCurrentStep,
    WorkflowType
} from "src/common/enums";
import { BaselinePermissionsEmployeeData } from "src/models/baseline-permissions-employee-data";
import { logger } from "src/logger";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetActiveWorkflowStateData, GetActiveWorkflowStateVariables } from "src/models/get-active-workflow-state";
import { GET_ACTIVE_WORKFLOW_STATE_QUERY, SIGNAL_WORKFLOW_MUTATION } from "src/common/gql-operations";
import {
    ACCESS_DENIED,
    ABBREVIATION_TO_PERMISSION_MAP,
    NETWORK_ONLY,
    NO_ACTIVE_WORKFLOW_FOUND,
    PERMISSION_BASELINING_DATA,
    PERMISSION_TO_ABBREVIATION_MAP,
    PRINCIPAL_LIST_URL,
    S3_PULLED_DATA,
    SIGNAL_WORKFLOW_WAKE_STATE_INVALID,
} from "src/common/constants";
import { SignalWorkflowVariables } from "src/models/signal-workflow";
import { useTranslation } from "react-i18next";
import { generateBaseliningPermissionWorkflowArn } from "src/common/util";
import { BreadCrumbs } from "src/components/breadcrumb";
import DefaultErrorPage from "src/pages/default-error-page";
import { AlertBar, AlertBarProps } from "src/components/alert-bar";
import { FallbackSpinner } from "src/components/fallback-spinner";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'baseline-permissions'),
]
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const baselineMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('ALL', additionalMetricsContext);
const BASELINE_PERMISSION_PAGE_PATH_PREFIX = '/permission/baseline/'

/**
 * Component to create Baseline permissions Page.
 */
export const BaselinePermissions = (props: PageProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isValidPath, setIsValidPath] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [siteId, setSitedId] = useState<string>();
    const [employeesData, setEmployeesData] = useState<BaselinePermissionsEmployeeData[]>([]);
    const [permissionId, setPermissionId] = useState<string>();
    const [permissionName, setPermissionName] = useState<string>();
    const [expectedSignalId, setExpectedSignalId] = useState<number>(0);
    const [isBaseliningBatchSubmitted, setIsBaseliningBatchSubmitted] = useState<boolean>(false);
    const [baseliningMessage, setBaseliningMessage] = useState<string>();
    const [permissionBaselineSelectionMap, setPermissionBaselineSelectionMap] =
        useState<Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>>(new Map());
    const [alertBarProps, setAlertBarProps] = useState<AlertBarProps|undefined>();

    const [getActiveWorkflowState, getActiveWorkflowStateResults] =
        useLazyQuery<GetActiveWorkflowStateData, GetActiveWorkflowStateVariables>(GET_ACTIVE_WORKFLOW_STATE_QUERY, {
            fetchPolicy: NETWORK_ONLY,
            onCompleted: data => {
                logger.info(`Retrieved data from getActiveWorkflowState for workflowId ${data.getActiveWorkflowState.workflowDetails.workflowId}`);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 1);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 0);

                // If no other signals reach the workflow, the next (perhaps sent from this UI) signal will expect to have
                // signalId = wakeIndex + 1
                setExpectedSignalId(data.getActiveWorkflowState.workflowDetails.workflowSignalWakeIndex! + 1);

                if (WorkflowCurrentStep.BASELINING_BATCH_PROCESSED === data.getActiveWorkflowState.workflowDetails.workflowCurrentStep
                    || WorkflowCurrentStep.TERMINATION_INITIATED === data.getActiveWorkflowState.workflowDetails.workflowCurrentStep) {
                    baselineMetricPublisher.publishCounter('BaselinePermissionsBatchAlreadySubmitted', 1);
                    setIsBaseliningBatchSubmitted(true);
                    setBaseliningMessage(t('baseline-permissions-batch-submitted-already'));
                    return;
                }
                const workflowDetails = data.getActiveWorkflowState.workflowDetails;
                const workflowId = workflowDetails.workflowId;
                const workflowStateData = workflowDetails.workflowStateData;
                setEmployeesData(getEmployeesDataFromWorkflowStateData(
                    workflowId,
                    workflowStateData)
                );
                setPermissionName(getPermissionNameFromWorkflowStateData(
                    workflowId,
                    workflowStateData));

                const permissionsBaselineSelectionMap:
                    Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation> = new Map();
                employeesData.map((employeeData: BaselinePermissionsEmployeeData) => {
                    permissionsBaselineSelectionMap.set(employeeData, employeeData.proposedPermission)});
                setPermissionBaselineSelectionMap(permissionsBaselineSelectionMap);
            },
            onError: error => {
                let accessDenied = false;
                error.graphQLErrors.forEach((gqlError) => {
                    logger.info(`gqlError.message = ${gqlError.message}`);
                    if (gqlError.message.includes(NO_ACTIVE_WORKFLOW_FOUND)) {
                        logger.info("No Active baselining permissions workflow found.");
                        baselineMetricPublisher.publishCounter('get-active-workflow-state.ActiveWorkflowNotFound', 1);
                        setIsBaseliningBatchSubmitted(true);
                        setBaseliningMessage(t('baseline-permissions-batch-submitted-already'));
                        return;
                    } else if (gqlError.message.includes(ACCESS_DENIED)) {
                        logger.info("Access denied to getActiveWorkflowState.");
                        baselineMetricPublisher.publishCounter('get-active-workflow-state.AccessDenied', 1);
                        accessDenied = true;
                        return;
                    }
                });

                logger.error("Call to getActiveWorkflowState failed!", error);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 1);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 0);
                if  (accessDenied) {
                    // *-alpha/gamma key values, so it can be updated later
                    setAlertBarProps({
                        result: AlertType.error,
                        header: t('sox-permissions-get-active-workflow-state-access-denied-header-alpha'),
                        message: t('sox-permissions-get-active-workflow-state-access-denied-message-gamma'),
                        dismissible: false,
                        buttonText: t('request-access'),
                        buttonLink: getAccessChangeRequestSimLink(error.message),
                    });
                } else {
                    setAlertBarProps({
                        result: AlertType.error,
                        header: t('failed-to-get-active-baselining-permissions-workflow-status'),
                        message: error.message,
                        dismissible: false,
                    });
                }
            }
        });

    const [signalWorkflow, signalWorkflowResults] = useMutation<any, SignalWorkflowVariables>(SIGNAL_WORKFLOW_MUTATION, {
        onCompleted: () => {
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 1);
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 0);
            logger.info("Successfully signalled workflow for baselinePermissionsWorkflow.");

            setModalOpen(false);
            setIsBaseliningBatchSubmitted(true);
            setBaseliningMessage(t('baseline-permissions-batch-submitted-successfully'));
            baselineMetricPublisher.publishCounter('PermissionsBaselined', employeesData.length);
            const numModifiedPermissions: number = getModifiedFromCurrentPermissions().length;
            baselineMetricPublisher.publishCounter('PermissionsModified', numModifiedPermissions);
            baselineMetricPublisher.publishCounter('PermissionsKept', employeesData.length - numModifiedPermissions);
        },
        onError: error => {
            let signalWorkflowError = false;
            error.graphQLErrors.forEach((gqlError) => {
                if (gqlError.message.includes(SIGNAL_WORKFLOW_WAKE_STATE_INVALID)) {
                    signalWorkflowError = true;
                    logger.info(`Invalid wake index error: ${gqlError.message}`)
                    baselineMetricPublisher.publishCounter('signal-workflow.InvalidWakeIndex', 1);
                    return;
                }
            });
            logger.error(`Call to signalWorkflow to submit baselining permissions results failed!`, error);
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 1);
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 0);
            if (signalWorkflowError) {
                // *-alpha key values, so it can be updated later
                setAlertBarProps({
                    result: AlertType.error,
                    header: t('sox-permissions-signal-workflow-wake-state-invalid-header-alpha'),
                    message: t('sox-permissions-signal-workflow-wake-state-invalid-message-alpha'),
                    dismissible: false,
                });
            } else {
                setAlertBarProps({
                    result: AlertType.error,
                    header: t('failed-to-signal-baselining-permissions-workflow'),
                    message: error.message,
                    dismissible: false
                });
            }
        }
    });

    /**
     * Creates the SIM link with prefilled in description to make it easier on the requester.
     * @param errorMessage
     */
    const getAccessChangeRequestSimLink = (errorMessage: string): string => {
        return `https://sim.amazon.com/issues/create?template=37a5b5e7-ee14-4515-abf2-d51758b515ec&description=${
            encodeURIComponent("Fill in the information in the custom fields below to request a reviewer/admin change at the designated location.\n" +
                "\n" +
                "Thank you!\n" +
                "\n" +
                "Please note: We currently only support one person to view a location, this limitation is planned to be lifted in Q2 2024.\n" +
                "\n" +
                "FC: " + siteId + "\n" +
                "Permission Id: " + permissionId + "\n" +
                "\n" +
                "Specific Error Message\n" +
                "```\n" +
                errorMessage +
                "```")}&descriptionContentType=text%2Famz-markdown-sim&extensions%5Btt%5D%5Bimpact%5D=5&extensions%5Btt%5D%5Bcategory%5D=&authorizations%5B%5D=BREAK&authZCompression=v1`
    }

    const getEmployeesDataFromWorkflowStateData = (workflowId: string, workflowStateData?: string): BaselinePermissionsEmployeeData[]=> {
        let employeesData : BaselinePermissionsEmployeeData[] = [];
        if (workflowStateData) {
            logger.info(`Found workflowStateData ${workflowStateData} for workflowId ${workflowId}`);
            const s3pulledData : string = JSON.parse(workflowStateData)[S3_PULLED_DATA];

            if (s3pulledData) {
                const permissionBaseliningData : string = JSON.parse(s3pulledData)[PERMISSION_BASELINING_DATA];

                if (permissionBaseliningData) {
                    logger.info(`Found permissionBaseliningData ${permissionBaseliningData} for workflowId ${workflowId}`);

                    const employeePermissionDataList : string[] = permissionBaseliningData.split("|");
                    employeePermissionDataList.forEach(employeePermissionData => {
                        const dataSegments : string[] = employeePermissionData.split(":");
                        if(dataSegments.length != 6) {
                            logger.error(`EmployeePermissionData ${employeePermissionData} invalid.`);
                            return;
                        }

                        const employeeId : string = dataSegments[0];
                        const employeeAlias: string = dataSegments[1];
                        const employeeFirstName: string = dataSegments[2];
                        const employeeLastName: string = dataSegments[3];
                        const currentPermissionAbbreviation : string = dataSegments[4];
                        const proposedPermissionAbbreviation : string = dataSegments[5];

                        if(!ABBREVIATION_TO_PERMISSION_MAP.has(currentPermissionAbbreviation)) {
                            logger.error(`Current permission abbreviation ${currentPermissionAbbreviation} is invalid.`);
                            return;
                        }

                        if(!ABBREVIATION_TO_PERMISSION_MAP.has(proposedPermissionAbbreviation)) {
                            logger.error(`Proposed permission abbreviation ${proposedPermissionAbbreviation} is invalid.`);
                            return;
                        }

                        const employeeData : BaselinePermissionsEmployeeData = {
                            employeeId : employeeId,
                            alias: employeeAlias,
                            firstName: employeeFirstName,
                            lastName: employeeLastName,
                            currentPermission : ABBREVIATION_TO_PERMISSION_MAP.get(currentPermissionAbbreviation)!,
                            proposedPermission : ABBREVIATION_TO_PERMISSION_MAP.get(proposedPermissionAbbreviation)!
                        };

                        employeesData.push(employeeData);
                    });
                }
            }
        }
        return employeesData;
    }

    const getPermissionNameFromWorkflowStateData = (workflowId: string, workflowStateData?: string): string => {
        let permissionName : string = "";
        if (workflowStateData) {
            permissionName = JSON.parse(workflowStateData)["permissionName"];
        }
        return permissionName;
    }

    const callGetActiveBaseliningPermissionsWorkflow = () => {
        if (siteId && permissionId) {
            baselineMetricPublisher.publishCounter('get-active-workflow-state.INVOCATION', 1)
            getActiveWorkflowState({
                variables: {
                    getActiveWorkflowStateInput: {
                        tenantId: TenantID.SOX,
                        principalArn: generateBaseliningPermissionWorkflowArn(siteId, permissionId),
                        workflowType: WorkflowType.BASELINING_PERMISSION
                    }
                }
            })
        }
    }

    // Extracts permissions that are modified from their current value according to page state
    const getModifiedFromCurrentPermissions = () : string[] => {
        let modifiedPermissions: string[] = [];
        permissionBaselineSelectionMap.forEach((value : BaseliningPermissionOperation,
                                                key : BaselinePermissionsEmployeeData) => {
            if(value != key.currentPermission) {
                const currentPermissionString : string =`${PERMISSION_TO_ABBREVIATION_MAP.get(key.currentPermission)}`;
                const proposedPermissionString : string = `${PERMISSION_TO_ABBREVIATION_MAP.get(value)}`;
                modifiedPermissions.push(`${key.employeeId}:${currentPermissionString}:${proposedPermissionString}`);
            }
        });
        return modifiedPermissions;
    }

    const signalSubmitBaseliningWorkflow = (siteId: string, permissionId: string) => {
        baselineMetricPublisher.publishCounter('signal-workflow.INVOCATION', 1)
        const modifiedPermissions: string[] = getModifiedFromCurrentPermissions();
        signalWorkflow({
            variables: {
                signalWorkflowInput: {
                    tenantId: TenantID.SOX,
                    principalArn: generateBaseliningPermissionWorkflowArn(siteId, permissionId),
                    workflowType: WorkflowType.BASELINING_PERMISSION,
                    workflowSignalData: `{\"submitModifiedPermissions\": \"${modifiedPermissions.join("|")}\"}`,
                    expectedSignalId: expectedSignalId
                }
            }
        })
    }

    const setInvalidPageState = () => {
        setIsValidPath(false)
        logger.info(' Invalid baselining url path : ' + location.pathname)
        baselineMetricPublisher.publishCounter('Invalid-Baselining-Permissions-url', 1);
    }

    useEffect(() => {
        if (location.pathname.startsWith(BASELINE_PERMISSION_PAGE_PATH_PREFIX)) {
            setIsValidPath(true)
            const pathname = location.pathname;
            const pathSplits = pathname.split('/');

            if(pathSplits.length < 5) {
                setInvalidPageState();
                return;
            }

            const siteId = pathSplits[4].toUpperCase();

            if (pathSplits.length > 6 || (pathSplits.length == 6 && pathSplits[5].length > 0)
                || siteId.length > 4  || !/^[a-z0-9]+$/i.test(siteId)) {
                setInvalidPageState();
                return;
            }

            const permissionId = pathname.split('/')[3];
            setSitedId(siteId)
            setPermissionId(permissionId)
            baselineMetricPublisher.publishCounter(`${permissionId}-BASELINING-PERMISSION`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-BASELINING-PERMISSION`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-${permissionId}-BASELINING-PERMISSION`, 1);

            // baselining permissions has its own set of sidebar options
            props.setActivePage(PageType.BASELINE_PERMISSION);
        } else {
            setInvalidPageState();
        }
    }, [])


    useEffect(() => {
        callGetActiveBaseliningPermissionsWorkflow();
    }, [siteId, permissionId])


    const renderFallbackSpinnerWhenProcessingSignalling = () => {
        if (signalWorkflowResults.loading) {
            return <FallbackSpinner />
        }
    }

    function generateButtonOptions(employeeData : BaselinePermissionsEmployeeData) {
        const operations : BaseliningPermissionOperation[] = [
            BaseliningPermissionOperation.NONE,
            BaseliningPermissionOperation.BEGINNER,
            BaseliningPermissionOperation.INTERMEDIATE,
            BaseliningPermissionOperation.EXPERT,
            BaseliningPermissionOperation.ADMIN
        ]
        let buttons = [];
        const maximumOperation : BaseliningPermissionOperation = employeeData.currentPermission;
        let greaterThanFinalOperation : boolean = false;
        for(let i = 0; i < operations.length; i++) {
            const operation : BaseliningPermissionOperation = operations[i];
            buttons.push({
                value: operation,
                label: `${t(operation.toLowerCase())}`,
                disabled: greaterThanFinalOperation
            });
            // Further buttons should be disabled
            if(operation == maximumOperation) {
                greaterThanFinalOperation = true;
            }
        }
        return buttons;
    }

    const switchModalOpen = () => {
        logger.info(`Setting modal open: ${!modalOpen}`)
        setModalOpen(!modalOpen);
    }

    const renderPageHeader = () => {
        if (isValidPath) {
            return (
                <div>
                    <div className="row">
                        <BreadCrumbs breadcrumbItems={[{tag: t('breadcrumb-baseline-permissions')}]}/>
                    </div>
                    <div className="row page-title justify-content-center title b-background mx-0 mb-0-5">
                        <span>
                            {t('baseline')}{` ${permissionName} (${t('permission-id')} ${permissionId})`}{`- ${t('site')} ${siteId}`}
                        </span>
                    </div>
                </div>
            )
        }
    }

    const renderAlertBar = () => {
        if (alertBarProps) {
            return (
                <AlertBar
                    id="baseline-permissions-alert-bar"
                    result={alertBarProps.result}
                    dismissible={alertBarProps.dismissible}
                    header={alertBarProps.header}
                    message={alertBarProps.message}
                    buttonText={alertBarProps.buttonText}
                    buttonLink={alertBarProps.buttonLink}
                />
            );
        }
    }

    const renderBaselineConfirmationModal = () => {
        return (
            <div className="row">
                <Modal id="baseline-permissions-modal"
                       className="mb-1"
                       header={t('baseline-permissions-modal-header')}
                       open={modalOpen}
                >
                    <div className="row mx-0 mb-0-5 justify-content-center">
                        <p id="baseline-permissions-modal-content">{t('baseline-permissions-modal-content')}</p>
                    </div>
                    <div className="container-fluid">
                        <div className="row mx-0 mb-0-5 d-flex justify-content-center">
                            <Button id='baseline-permissions-modal-confirm-btn' onClick={(e) => {signalSubmitBaseliningWorkflow(siteId!, permissionId!)}}>{t('confirm')}</Button>
                            <Button id='baseline-permissions-modal-cancel-btn' onClick={switchModalOpen}>{t('cancel')}</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    const renderPageBody = () => {
        if (!isValidPath) {
            return (<DefaultErrorPage setActivePage={props.setActivePage}/>);
        }

        if (isBaseliningBatchSubmitted) {
            return (
                <div className="d-flex flex-column">
                    <div className="row flex-grow-1 b-background justify-content-center title mx-0 mb-0-5">
                        <span>
                            {baseliningMessage}<br/>
                            {t('back-to-users-page')}
                        </span>
                    </div>
                    <div className="row b-background mx-0 mb-0-5 justify-content-center">
                        <Button id='baseline-permissions-back' label={`${t('back')}`} link={PRINCIPAL_LIST_URL} />
                    </div>
                </div>
            )

        } else {
            if (getActiveWorkflowStateResults.error) {
                return renderAlertBar()
            } if (getActiveWorkflowStateResults.loading) {
                return  (<FallbackSpinner/>)
            } else {
                return (
                    <div>
                        <div className="row users-table b-background mx-0 mb-0-5 table-responsive">
                            <table className="bordered bordered-vertical alchemy-table">

                                <thead>
                                <tr key='tableHeader'>
                                    <th>{t('employee-id')}</th>
                                    <th>{t('alias')}</th>
                                    <th>{t('first-name')}</th>
                                    <th>{t('last-name')}</th>
                                    <th>{t('baseline-permissions-action')}</th>
                                    <th>{t('current-permission')}</th>
                                    <th>{t('proposed-permission')}</th>
                                </tr>
                                </thead>

                                <tbody>
                                {employeesData.map((employeeData: BaselinePermissionsEmployeeData, index: number) => {
                                    return (<tr key={`key${index}`}>
                                        <td>{employeeData.employeeId}</td>
                                        <td>{employeeData.alias}</td>
                                        <td>{employeeData.firstName}</td>
                                        <td>{employeeData.lastName}</td>
                                        <td>
                                            <RadioButtons   id={`radio-btn-${index}`}
                                                            variant='horizontal'
                                                            buttons={generateButtonOptions(employeeData)}
                                                            // The state of option selection for radio button is manged by alchemy component hence setting here default which doesn't change value when re-renders
                                                            value={employeeData.proposedPermission}
                                                            onChange={
                                                                (e) => {
                                                                    setPermissionBaselineSelectionMap(new Map(permissionBaselineSelectionMap).set(employeeData, e.target.value));
                                                                }
                                                            }
                                            />
                                        </td>
                                        <td>{`${employeeData.currentPermission}`}</td>
                                        <td>
                                            {permissionBaselineSelectionMap.has(employeeData)
                                                    ? `${permissionBaselineSelectionMap.get(employeeData)!}`
                                                    : `${employeeData.proposedPermission}` }
                                        </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>

                        <div className="row b-background mx-0 mb-0-5 justify-content-center">
                            <Button id="submit-baseline-permissions-selection" label={`${t('submit')}`} onClick={switchModalOpen} />
                        </div>

                        {renderBaselineConfirmationModal()}
                        {renderFallbackSpinnerWhenProcessingSignalling()}
                        {renderAlertBar()}
                    </div>
                )
            }
        }
    }

    return (
        <div className="container-fluid">
            {renderPageHeader()}
            {renderPageBody()}
        </div>
    )
}

