import React, { useEffect, useState } from "react";
import { PageProps } from "src/pages/page-interface";
import { useLocation } from "react-router-dom";
import { Button, Modal, RadioButtons } from '@amzn/alchemy-components-react'
import {
    AlertType,
    BaseliningUserOperation,
    BaseliningState,
    PageType,
    UserType,
    WorkflowCurrentStep,
    WorkflowType,
    TenantID
} from "src/common/enums";
import { logger } from "src/logger";
import { useLazyQuery, useMutation } from "@apollo/client";
import {GetActiveWorkflowStateData, GetActiveWorkflowStateVariables} from "src/models/get-active-workflow-state";
import {
    GET_ACTIVE_WORKFLOW_STATE_QUERY,
    LIST_PRINCIPALS_QUERY,
    SIGNAL_WORKFLOW_MUTATION
} from "src/common/gql-operations";
import { NETWORK_ONLY, NO_ACTIVE_WORKFLOW_FOUND, PRINCIPAL_LIST_URL } from "src/common/constants";
import { SignalWorkflowVariables } from "src/models/signal-workflow";
import { ListPrincipalsData, ListPrincipalsVariables } from "src/models/list-principals";
import { useTranslation } from "react-i18next";
import { generateBaseliningPrincipalWorkflowArn } from "src/common/util";
import { Principal, Principals } from "src/models/principal";
import { BreadCrumbs } from "src/components/breadcrumb";
import DefaultErrorPage from "src/pages/default-error-page";
import { AlertBar, AlertBarProps } from "src/components/alert-bar";
import { FallbackSpinner } from "src/components/fallback-spinner";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'baseline-principals'),
]
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const baselineMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('ALL', additionalMetricsContext);
const BASELINE_PAGE_PATH_PREFIX = '/principal/baseline/'

/**
 * Component to create Baseline Principals Page.
 */
export const BaselinePrincipals = (props: PageProps) => {

    const initialPrincipals : Principals = {
        tenantId: TenantID.AFTX.toString(),
        principals: []
    }
    const { t } = useTranslation()
    const location = useLocation();
    const [isValidPath, setIsValidPath] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [userType, setUserType] = useState<string>(UserType.ASSOCIATE)
    const [siteId, setSitedId] = useState<string>()
    const [principals, setPrincipals] = useState(initialPrincipals)
    const [isBaseliningBatchSubmitted, setIsBaseliningBatchSubmitted] = useState<boolean>(false)
    const [expectedSignalId, setExpectedSignalId] = useState<number>(0);
    const [baseliningMessage, setBaseliningMessage] = useState<string>()
    const [principalBaselineSelectionMap, setprincipalBaselineSelectionMap] = useState<Map<string, BaseliningUserOperation>>(new Map())
    const [alertBarProps, setAlertBarProps] = useState<AlertBarProps|undefined>();

    const [getActiveWorkflowState, getActiveWorkflowStateResults] =
        useLazyQuery<GetActiveWorkflowStateData, GetActiveWorkflowStateVariables>(GET_ACTIVE_WORKFLOW_STATE_QUERY, {
            fetchPolicy: NETWORK_ONLY,
            onCompleted: data => {
                logger.info(`Retrieved data from getActiveWorkflowState for workflowId ${data.getActiveWorkflowState.workflowDetails.workflowId}`)
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 1)
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 0)

                // If no other signals reach the workflow, the next (perhaps sent from this UI) signal will expect to have
                // signalId = wakeIndex + 1
                setExpectedSignalId(data.getActiveWorkflowState.workflowDetails.workflowSignalWakeIndex! + 1);

                if (WorkflowCurrentStep.BASELINING_BATCH_PROCESSED === data.getActiveWorkflowState.workflowDetails.workflowCurrentStep
                    || WorkflowCurrentStep.TERMINATION_INITIATED === data.getActiveWorkflowState.workflowDetails.workflowCurrentStep) {
                    baselineMetricPublisher.publishCounter('BaselineBatchAlreadySubmitted', 1)
                    setIsBaseliningBatchSubmitted(true)
                    setBaseliningMessage(t('baseline-batch-submitted-already'))
                    return
                }
                callListPrincipalsByScope(data.getActiveWorkflowState.workflowDetails.creationDate)
            },
            onError: error => {
                error.graphQLErrors.forEach((gqlError) => {
                    if (gqlError.message.includes(NO_ACTIVE_WORKFLOW_FOUND)) {
                        logger.info("No Active baselining workflow found.");
                        baselineMetricPublisher.publishCounter('get-active-workflow-state.ActiveWorkflowNotFound', 1)
                        setIsBaseliningBatchSubmitted(true)
                        setBaseliningMessage(t('baseline-batch-submitted-already'))
                        return
                    }
                })
                logger.error("Call to getActiveWorkflowState failed!", error);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 1)
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 0)
                setAlertBarProps({
                    result: AlertType.error,
                    header: t('failed-to-get-active-baselining-workflow-status'),
                    message: error.message,
                    dismissible: false
                })
            }
        });

    const [listPrincipals, listPrincipalsResults] = useLazyQuery<ListPrincipalsData, ListPrincipalsVariables>(LIST_PRINCIPALS_QUERY, {
        fetchPolicy: NETWORK_ONLY,
        onCompleted: data => {
            logger.info(`Retrieved data from listPrincipals for tenantId ${data.listPrincipals.tenantId}`);
            baselineMetricPublisher.publishCounter('list-principals.SUCCESS', 1)
            baselineMetricPublisher.publishCounter('list-principals.ERROR', 0)
            const principalList: Principal[] = []
            const principalBaselineSelectionMap: Map<string, BaseliningUserOperation> = new Map()
            const tenantId = data.listPrincipals.tenantId
            if (0 === data.listPrincipals.principals.length) {
                logger.info("No Principals to baseline. Sending empty signal to close out workflow.");
                baselineMetricPublisher.publishCounter('list-principals.EmptyPrincipalList', 1);
                signalBaseliningWorkflow(siteId!, userType!, t('no-users-to-baseline'));
                return
            }
            data.listPrincipals.principals.map((principal: Principal) => {
                principalBaselineSelectionMap.set(principal.principalArn, BaseliningUserOperation.KEEP)
                principalList.push({
                    principalArn: principal.principalArn,
                    userType: principal.userType,
                    scope: principal.scope,
                    lastBaseliningDate: principal.lastBaseliningDate,
                    lastBaselinedByPrincipal: principal.lastBaselinedByPrincipal,
                    firstName: principal.firstName,
                    lastName: principal.lastName,
                    alias: principal.alias,
                    startDate: principal.startDate,
                    creationDate: principal.creationDate
                })

            })
            setprincipalBaselineSelectionMap(principalBaselineSelectionMap)
            setPrincipals({
                tenantId: tenantId,
                principals: principalList,
            })
        },
        onError: error => {
            logger.error("Call to listPrincipals failed!", error);
            baselineMetricPublisher.publishCounter('list-principals.ERROR', 1)
            baselineMetricPublisher.publishCounter('list-principals.SUCCESS', 0)
            setAlertBarProps({
                result: AlertType.error,
                header: t('list-principals-error-alert-header'),
                message: error.message,
                dismissible: false
            })
        }
    })

    const [signalWorkflow, signalWorkflowResults] = useMutation<any, SignalWorkflowVariables>(SIGNAL_WORKFLOW_MUTATION, {
        onCompleted: () => {
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 1)
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 0)
            logger.info("Successfully signalled workflow for baselineWorkflow.");
            setModalOpen(false)
            setIsBaseliningBatchSubmitted(true)
            baselineMetricPublisher.publishCounter('PrincipalsBaselined', principals.principals.length)
            const numRemovedPrincipals: number = [...principalBaselineSelectionMap.values()].filter(value => BaseliningUserOperation.REMOVE === value).length
            baselineMetricPublisher.publishCounter('PrincipalsRemoved', numRemovedPrincipals)
            baselineMetricPublisher.publishCounter('PrincipalsKept', principals.principals.length - numRemovedPrincipals)
        },
        onError: error => {
            logger.error(`Call to signalWorkflow to submit baselining results failed!`, error);
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 1)
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 0)
            setAlertBarProps({
                result: AlertType.error,
                header: t('failed-to-submit-baselining-batch-results'),
                message: error.message,
                dismissible: false
            })
        }
    });

    const callListPrincipalsByScope = (lastBaselineBefore: number) => {
        baselineMetricPublisher.publishCounter('list-principals.InvocationCount', 1)
        listPrincipals({
            variables: {
                listPrincipalsInput: {
                    tenantId: TenantID.AFTX,
                    scope: siteId,
                    userTypes: [userType!],
                    baselineStates: [BaseliningState.ALLOWED, BaseliningState.NOT_PERFORMED]
                }
            }
        })
    }

    const callGetActiveBaseliningWorkflow = () => {
        if (siteId && userType) {
            baselineMetricPublisher.publishCounter('get-active-workflow-state.INVOCATION', 1)
            getActiveWorkflowState({
                variables: {
                    getActiveWorkflowStateInput: {
                        tenantId: TenantID.AFTX,
                        principalArn: generateBaseliningPrincipalWorkflowArn(siteId),
                        workflowType: convertUserTypeToWorkflowType(userType)
                    }
                }
            })
        }
    }

    const convertUserTypeToWorkflowType = (userType: string) : string => {
        switch(userType) {
            case UserType.ASSOCIATE:
                return WorkflowType.BASELINING_ASSOCIATE;
            case UserType.USER_ADMIN_SECONDARY:
                return WorkflowType.BASELINING_USER_ADMIN_SECONDARY;
            case UserType.USER_ADMIN_PRIMARY:
                return WorkflowType.BASELINING_USER_ADMIN_PRIMARY;
            default:
                logger.info(`Invalid userType ${userType} does not have corresponding baselining workflow type.
                             Returning with empty workflow type.`);
                return "";
        }
    }

    const signalBaseliningWorkflow = (siteId: string, userType: string, baseliningMessage: string) => {
        baselineMetricPublisher.publishCounter('signal-workflow.INVOCATION', 1)
        let removedPrincipalArns: string[] = []
        principalBaselineSelectionMap.forEach((value, key) => {
            if (BaseliningUserOperation.REMOVE === value) {
                removedPrincipalArns.push(`'${key}'`)
            }
        })
        signalWorkflow({
                variables: {
                    signalWorkflowInput: {
                        tenantId: TenantID.AFTX,
                        principalArn: generateBaseliningPrincipalWorkflowArn(siteId),
                        workflowType: convertUserTypeToWorkflowType(userType),
                        workflowSignalData: `{\"removedPrincipals\": \"[${removedPrincipalArns.join(', ')}]\"}`,
                        expectedSignalId: expectedSignalId
                    }
            }
        })
        setBaseliningMessage(baseliningMessage)
    }

    const setInvalidPageState = () => {
        setIsValidPath(false)
        logger.info(' Invalid baselining url path : ' + location.pathname)
        baselineMetricPublisher.publishCounter('Invalid-Baselining-url', 1);
    }

    useEffect(() => {
        if (location.pathname.startsWith(BASELINE_PAGE_PATH_PREFIX + UserType.ASSOCIATE.toLowerCase() + '/')
            || location.pathname.startsWith(BASELINE_PAGE_PATH_PREFIX + UserType.USER_ADMIN_PRIMARY.toLowerCase() + '/')
            || location.pathname.startsWith(BASELINE_PAGE_PATH_PREFIX + UserType.USER_ADMIN_SECONDARY.toLowerCase() + '/')) {

            setIsValidPath(true)
            const pathname = location.pathname
            const pathSplits = pathname.split('/');
            const siteId = pathSplits[4].toUpperCase()

            if (pathSplits.length > 6 || (pathSplits.length == 6 && pathSplits[5].length > 0 )|| siteId.length > 4
                || !/^[a-z0-9]+$/i.test(siteId)) {
                setInvalidPageState()
                return
            }

            const userType = pathname.split('/')[3].toUpperCase()
            setSitedId(siteId)
            setUserType(userType)
            baselineMetricPublisher.publishCounter(`${userType}-BASELINING`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-BASELINING`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-${userType}-BASELINING`, 1);

            // baselining page can be navigated from users page
            props.setActivePage(PageType.PRINCIPAL_LIST)
        } else {
            setInvalidPageState()
        }
    }, [])


    useEffect(() => {
        callGetActiveBaseliningWorkflow()
    }, [siteId, userType])


    const renderFallbackSpinnerWhenProcessingSignalling = () => {
        if (signalWorkflowResults.loading) {
            return <FallbackSpinner />
        }
    }

    const switchModalOpen = () => {
        setModalOpen(!modalOpen)
    }

    const renderPageHeader = () => {
        if (isValidPath) {
            return (
                <div>
                    <div className="row">
                        <BreadCrumbs breadcrumbItems={[{tag: t('breadcrumb-baseline-users')}]}/>
                    </div>
                    <div className="row page-title justify-content-center title b-background mx-0 mb-0-5">
                        <span>
                            {t('baseline')} {siteId} {getDisplayNameFromUserType(userType)}
                        </span>
                    </div>
                </div>
            )
        }
    }

    const renderAlertBar = () => {
        if (alertBarProps) {
            return (
                <AlertBar
                    id="baseline-alert-bar"
                    result={alertBarProps.result}
                    dismissible={alertBarProps.dismissible}
                    header={alertBarProps.header}
                    message={alertBarProps.message}
                />
            )
        }
    }

    const renderBaselineConfirmationModal = () => {
        return (
            <div className="row">
                <Modal id="baseline-principals-modal"
                       className="mb-1"
                       header={t('baseline-principals-modal-header')}
                       open={modalOpen}
                >
                    <div className="row mx-0 mb-0-5 justify-content-center">
                        <p id="baseline-principals-modal-content">{t('baseline-principals-modal-content')}</p>
                    </div>
                    <div className="container-fluid">
                        <div className="row mx-0 mb-0-5 d-flex justify-content-center">
                            <Button id='baseline-principals-modal-confirm-btn' onClick={(e) => {
                                signalBaseliningWorkflow(siteId!, userType!, t('baseline-batch-submitted-successfully'))
                            }}>{t('confirm')}</Button>
                            <Button id='baseline-principals-modal-cancel-btn' onClick={switchModalOpen}>{t('cancel')}</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    const getDisplayNameFromUserType = (userType: string) => {
        switch(userType) {
            case UserType.ASSOCIATE: {
                return "Associates";
            }
            case UserType.USER_ADMIN_PRIMARY: {
                return "Primary User Admins";
            }
            case UserType.USER_ADMIN_SECONDARY: {
                return "Secondary User Admins";
            }
            case UserType.SYSTEM_ADMIN: {
                return "System Admins";
            }
            default: {
                return "Associates";
            }
        }
    }

    const renderPageBody = () => {
        if (!isValidPath) {
            return (<DefaultErrorPage setActivePage={props.setActivePage}/>)
        }

        if (isBaseliningBatchSubmitted) {
            return (
                <div className="d-flex flex-column">
                    <div className="row flex-grow-1 b-background justify-content-center title mx-0 mb-0-5">
                        <span>
                            {baseliningMessage}<br/>
                            {t('back-to-users-page')}
                        </span>
                    </div>
                    <div className="row b-background mx-0 mb-0-5 justify-content-center">
                        <Button id='baseline-back' label={`${t('back')}`} link={PRINCIPAL_LIST_URL} />
                    </div>
                </div>
            )

        } else {
            if (getActiveWorkflowStateResults.error || listPrincipalsResults.error) {
                return renderAlertBar()
            } if (getActiveWorkflowStateResults.loading || listPrincipalsResults.loading) {
                return  (<FallbackSpinner/>)
            } else {
                return (
                    <div>
                        <div className="row users-table b-background mx-0 mb-0-5 table-responsive">
                            <table className="bordered bordered-vertical alchemy-table">

                                <thead>
                                    <tr key='tableHeader'>
                                        <th>{t('user-name')}</th>
                                        <th>{t('baseline-action')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {principals.principals.map((principal: Principal, index: number) => {
                                    return (<tr key={`key${index}`}>
                                        <td>{principal.firstName} {principal.lastName}</td>
                                        <td>
                                            <RadioButtons   id={`radio-btn-${index}`}
                                                            variant='horizontal'
                                                            buttons={
                                                                [
                                                                    {value:BaseliningUserOperation.KEEP, label:`${t(BaseliningUserOperation.KEEP.toLowerCase())}`},
                                                                    {value:BaseliningUserOperation.REMOVE, label:`${t(BaseliningUserOperation.REMOVE.toLowerCase())}`}
                                                                ]}
                                                            // The state of option selection for radio button is manged by alchemy component hence setting here default KEEP value which doesnt change value when re-renders
                                                            value={BaseliningUserOperation.KEEP}
                                                            onChange={(e) => principalBaselineSelectionMap.set(principal.principalArn, e.target.value)}
                                            />
                                        </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>

                        <div className="row b-background mx-0 mb-0-5 justify-content-center">
                            <Button id="submit-baseline-selection" label={`${t('submit')}`} onClick={switchModalOpen}/>
                        </div>

                        {renderBaselineConfirmationModal()}
                        {renderFallbackSpinnerWhenProcessingSignalling()}
                        {renderAlertBar()}
                    </div>
                )
            }
        }
    }

    return (
        <div className="container-fluid">
            {renderPageHeader()}
            {renderPageBody()}
        </div>
    )
}