import React from 'react'
import { useTranslation } from 'react-i18next'
import { FallbackSpinner } from 'src/components/fallback-spinner'
import { PrincipalSummaryCard } from 'src/components/cards/principal-summary-card'
import { PrincipalAction } from 'src/components/principal-action'
import { AlertBar } from "src/components/alert-bar";
import {AlertType, TenantID} from "src/common/enums";
import { RegisterMfaModalHooks } from "src/models/register-mfa-modal-hooks";
import { RemoveScopeModalHooks } from "src/models/remove-principal-scope-modal-hooks";
import { PrincipalCard } from "src/models/principal-card";

interface RenderPrincipalCardsProps {
  loading: boolean
  errorMessage?: string
  principalCards: Map<string, PrincipalCard>
  toggleExpand: (principalArn: string) => void
  toggleSelect: (principalArn: string) => void
  registerMfaModalHooks: RegisterMfaModalHooks
  removeScopeModalHooks: RemoveScopeModalHooks
}

export const RenderPrincipalCards = (renderPrincipalCardsProps: RenderPrincipalCardsProps) => {
  const { t } = useTranslation()

  if (renderPrincipalCardsProps.loading) {
    return <FallbackSpinner />
  } else if (renderPrincipalCardsProps.errorMessage) {
    return  <AlertBar id="principal-list-alert-bar"
                      result={AlertType.error}
                      dismissible={false}
                      header={t('list-principals-error-alert-header')}
                      message={renderPrincipalCardsProps.errorMessage}
    />
  } else {
    let nodes: React.ReactNode[] = [];

    if (renderPrincipalCardsProps.principalCards) {
      renderPrincipalCardsProps.principalCards.forEach((principalCard, principalArn) => {
        nodes.push(
            <PrincipalSummaryCard
                key={principalArn}
                cardKey={principalArn}
                selected={principalCard.selected}
                summaryCardHeader={`${principalCard.fullName} : ${principalCard.userType}`}
                summaryCardItems={principalCard.items}
                actionComponent={
                  <PrincipalAction
                      principalArn={principalArn}
                      fullName={principalCard.fullName}
                      tenantId={TenantID.AFTX}
                      scope={principalCard.scope}
                      userType={principalCard.userType}
                      className="summary-card-action"
                      registerMfaModalHooks={renderPrincipalCardsProps.registerMfaModalHooks}
                      removeScopeModalHooks={renderPrincipalCardsProps.removeScopeModalHooks}
                  />
                }
                toggleExpand={renderPrincipalCardsProps.toggleExpand}
                toggleSelect={renderPrincipalCardsProps.toggleSelect}
                expanded={principalCard.expanded}
            />
        )
      })
    }

    return <div id="principal-cards">{nodes}</div>;
  }
}