import fetch from "cross-fetch";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { TokenManager } from '@amzn/fitjava-script-client';
import { tokenProviderInstance } from '../components/auth/token-provider';
import { logger } from "src/logger";
import { signOut } from "src/common/util";
import { TokenUtil } from "src/common/token-util";

// Filled in from webpack
const graphQLEndpoint = 'https://api.fit.us-west-2.aft.amazonoperations.app/graphql'

const authLink = setContext((request, previousContext) => {
    if (TokenUtil.isTokenExpired(TokenManager.getRefreshToken())) {
        logger.info("refresh_token has expired.");
        signOut();
    } else if (TokenUtil.isTokenExpired(TokenManager.getIdToken())) {
        logger.info("id_token has expired so refreshing it.")
        tokenProviderInstance.refreshToken();
    }
    return {
        headers: {
            "Fit-Authorization-Token": TokenManager.getIdToken()
        },
    };
});

const httpLink = createHttpLink({
    uri: graphQLEndpoint,
    fetch,
});

let client: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * Gets the Apollo Client (singleton).
 */
export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
    // Check if client not created yet
    if (!client) {
        // Create client
        client = new ApolloClient({
            link: ApolloLink.from([authLink, httpLink]),
            cache: new InMemoryCache()
        });
    }
    return client;
}