import { logger } from "src/logger";
import { Enums, TokenManager } from "@amzn/fitjava-script-client";
import { REGION, ENTERPRISE_SCOPE } from "src/common/constants";
import { TenantID } from "src/common/enums";
import {
    ACCESS_TOKEN_DURATION,
    EXP_CLAIM,
    ID_TOKEN_DURATION,
    PRM_ID_CLAIM,
    REFRESH_TOKEN_DURATION,
    TOKEN_BUFFER_TIME,
    TOKEN_USE_CLAIM
} from "@amzn/fitjava-script-client/dist/common/constants";

export class TokenUtil {

    /**
     * Checks if the token is "deemed" as expired.
     *
     * @param token The token to check.
     * @returns true if token has expired, is about to expire, or browser's time is way behind; otherwise, false.
     */
    public static isTokenExpired = (token: string | null | undefined): boolean => {
        if (!token) {
            logger.info('Token is null, undefined, or empty; marking token as expired.');
            return true;
        }

        try {
            const tokenUse = TokenManager.getTokenClaim(token, TOKEN_USE_CLAIM);
            const exp = TokenManager.getTokenClaim(token, EXP_CLAIM);

            let tokenDuration;
            switch (tokenUse) {
                case Enums.TokenUse.ACCESS:
                    tokenDuration = ACCESS_TOKEN_DURATION;
                    break;
                case Enums.TokenUse.ID:
                    tokenDuration = ID_TOKEN_DURATION;
                    break;
                case Enums.TokenUse.REFRESH:
                    tokenDuration = REFRESH_TOKEN_DURATION;
                    break;
                default:
                    logger.warn(`Invalid token_use: ${tokenUse}; flagging token as expired.`);
                    return true;
            }

            const nowDate = new Date();
            const expDate = new Date(exp * 1000); // Convert to milliseconds

            const nowTime = nowDate.getTime();
            const expTime = expDate.getTime();
            const diff = (expTime - nowTime) / 1000;

            const hasExpired = diff < 0;
            const isAboutToExpire = !hasExpired && (diff < TOKEN_BUFFER_TIME);
            // Add a buffer to the token duration because the browser and server date time can be out of sync
            const isBrowserTimeWayBehind = (diff > (tokenDuration + TOKEN_BUFFER_TIME));

            if (hasExpired) {
                logger.info(`${tokenUse} token has expired. Expired ${diff * -1} seconds ago.`);
                return true; // token has expired
            } else if (isAboutToExpire) {
                logger.info(`${tokenUse} token is about to expire in ${diff} seconds; flagging it as expired after adding a buffer of ${TOKEN_BUFFER_TIME} seconds.`);
                return true; // token is about to expire
            } else if (isBrowserTimeWayBehind) {
                logger.info(`Browser's time is way behind: ${nowTime} > (${expTime} + ${TOKEN_BUFFER_TIME}) by ${diff - TOKEN_BUFFER_TIME} seconds; flagging ${tokenUse} token as expired.`);
                return true; // browser's time is way behind
            } else {
                return false; // token is valid
            }
        } catch (e) {
            logger.error('Flagging token as expired since an unexpected error occurred!', e as Error);
            return true;
        }
    }

    /**
     * Gets the Principal ARN using the PRM ID from the FIT id_token.
     *
     * @return the Principal ARN or {@code null} if the id_token is {@code null}.
     */
    public static getPrincipalArn = (): string | null => {
        const idToken = TokenManager.getIdToken();

        if (idToken) {
            try {
                const prmId = TokenManager.getTokenClaim(idToken, PRM_ID_CLAIM);
                return `arn:aftx:${REGION}:${TenantID.AFTX}:${ENTERPRISE_SCOPE}:personnel/${prmId}`;
            } catch (e) {
                logger.error(`Failed to get prm_id from id_token: ${idToken}`, e as Error);
            }
        }

        return null;
    }
}