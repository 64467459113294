import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Button } from '@amzn/alchemy-components-react'

/**
 * Properties for the Workflow Action Menu.
 */
interface WorkflowActionMenuProps {
    className: string
}

/**
 * Component to create Workflow Action Menu dropdown.
 */
export const WorkflowActionMenu = (workflowActionMenuProps: WorkflowActionMenuProps) => {
    const {t} = useTranslation()

    const [menuIcon, setMenuIcon] = useState('caretDown')

    // TODO: Implement redrive
    // https://issues.amazon.com/issues/FAAS-1888
    const redrive = {
        label: t('redrive'),
        link: '#',
        disabled: true
    }

    // TODO: Implement cancel
    // https://issues.amazon.com/issues/AFTI-926
    const cancel = {
        label: t('cancel'),
        link: '#',
        disabled: true
    }

    const actionMenuOptions = []
    actionMenuOptions.push(redrive)
    actionMenuOptions.push(cancel)

    return (
        // TODO: Remove 'hidden' below once an action is implemented
        <Menu className={workflowActionMenuProps.className}
              id="workflow-action-menu"
              options={actionMenuOptions}
              onClose={() => setMenuIcon('caretDown')}
              onOpen={() => setMenuIcon('caretUp')}
              hidden>
            <span slot="trigger">
                <Button icon={menuIcon} iconPosition="right">
                    {t('actions')}
                </Button>
            </span>
        </Menu>
    )
}
