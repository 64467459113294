import React, { ReactElement, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { tokenProviderInstance } from './token-provider';
import { AUTH_URL } from "src/common/constants";
import { getPrincipalScopes } from "src/components/auth/scopes-provider";
import { logger } from "src/logger";
import { TokenManager } from '@amzn/fitjava-script-client';
import { TokenUtil } from "src/common/token-util";

// Create the context with a default mock function
const authContext = React.createContext({
    isAuthenticated: false,
    setAuthState: (isAuthed: boolean): void => {
        return;
    },
    getAuthUrl: (loginHint: string | undefined = undefined): string => {
        return '';
    },
    storeAuthCode: (authCode: string): void => {
        return;
    },
    getToken: (): Promise<void> => {
        return new Promise<void>(() => {
            return;
        });
    },
    getScopes: (): Promise<string[]> => {
        return new Promise<string[]>(() => {
            return;
        });
    }
});

export function AuthProvider({ children }: { children: ReactElement<any, any> }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
    return React.useContext(authContext);
}

function useProvideAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(TokenManager.tokensExist());

    const setAuthState = (isAuthed: boolean): void => {
        setIsAuthenticated(isAuthed);
    };

    const getAuthUrl = (loginHint: string | undefined = undefined): string => {
        return tokenProviderInstance.getAuthUrl(loginHint);
    };

    const storeAuthCode = (authCode: string): void => {
        tokenProviderInstance.storeAuthCode(authCode);
    };

    const getToken = (): Promise<void> => {
        return tokenProviderInstance.getToken();
    };

    const getScopes = async (): Promise<string[]> => {
        try {
            const principalArn = TokenUtil.getPrincipalArn();
            return await getPrincipalScopes(principalArn!);
        } catch (e) {
            logger.error('Failed to get scopes.', e as Error);
            return [];
        }
    }

    return { isAuthenticated, setAuthState, getAuthUrl, storeAuthCode, getToken, getScopes };
}

// If there's no token present, redirect to auth callback page automatically without any auth code
// If auth callback page is accessed without auth code, do the redirection to FIT
export function AuthRequiredRoute({ children, path, ...rest }: any) {
    const auth = useAuth()!;
    return (
        <Route
            path={path}
            {...rest}
            render={({ location }) =>
                auth.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: AUTH_URL,
                            state: {
                                from: location,
                            },
                        }}
                    />
                )
            }
        />
    );
}
